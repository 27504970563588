import React, { useEffect } from "react";
import { Collapse } from "antd";
import styles from "./faq.module.css"

const Faq = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const questions = [
        {
            key: '1',
            label: 'Hansı növ məhsulları təklif edirsiniz?',
            children: <p>Biz geniş çeşiddə keramik plitələr, qranit plitələr (keramoqranit), pilləkənlər, laminatlar,
            sanxtexnika məhsulları, hamam aksesuarları, moydadırlar, unitaz və vannalar, duş kabinlər təklif
            edirik.</p>
        },
        {
            key: '2',
            label: 'Məhsullarınız hara istehsalıdır?',
            children: <p>Çanaqqala Seramik olaraq Türkiyə, İspaniya, İtaliya, Çin, Ukrayna, Rusiya, İran, Özbəkistan,
            Hindistan istehsalı başda olmaqla Kale Seramik, Decovita, BİEN Seramik, QUA Granite, FANAL,
            Pamesa, Undefasa, GoldenTile daxil 30dan çox dünyanın seramika sahəsində qabaqcıl brendlərinin
            Azərbaycan üzrə nümayəndəsi kimi ölkənin ən bol çeşidli məhsul satışını yerinə yetiririk.</p>
        },
        {
            key: '3',
            label: 'Sərgi salon/mağazalarınız harada yerləşir?',
            children: <p>“Çanakkale Seramik” və “Kale Seramik” adlı iki mağazamız Xətai prosepktində, Təhsil
            Nazirliyinin yanında və üzbəüzündə yerləşir.</p>
        },
        {
            key: '4',
            label: 'Sizin məhsulları rayon vəya digər firmaların mağazalarında da görmüşəm. Aralarında hərhansı bir fərq var?',
            children: <p>Çanaqqala Seramik, 30 ilə yaxındır Azərbaycanın ən böyük topdan və pərakəndə seramik
            satışını yerinə yetirdiyi üçün ölkə üzrə 150-ə yaxın mağaza və dükanla əməkdaşlıq edərək
            məhsullarımızın hərkəs üçün əlçatan olmasını təmin edir.</p>
        },
        {
            key: '5',
            label: 'Məhsullarınızın keyfiyyətinə zəmanət verirsiniz?',
            children: <p>Bəli, bizim satışını etdiyimiz məhsullar ən son texnologiya və praktika ilə işləyən zavodlarda
            istehsal olunduğu üçün onların keyfiyyətinə zəmanət veririk. Sözügedən zavodlar Beynəlxalq
            standardların tələblərinə əsasən fəaliyyət göstərdiyindən CE / Uyğunluq, ISO 9001, ISO 14001, ISO
            27001, ISO 45001, sertifikatları daxil əksər sertifikatların hamısı ilə təmin olunmuşdur. Ehtiyac
            daxilində müştərilərimiz bu sertifikatları bizdən tələb edə bilər.</p>
        },
        {
            key: '6',
            label: 'Məhsul seçimi çoxdu, qərarsız qalmışam nə almalıyam. Necə köməklik edə bilərsiniz?',
            children: <p>Müştərilərimizin məmuniyyətini təmin etmək üçün 30 nəfərlik təcrübəli satış və dizayner heyətimiz
            sizə məhsul seçimində öz təklif və təcrübələrini paylaşmaqla yanaşı, bizdən seçdiyiniz məhsulların
            ödənişsiz şəkildə vizual görüntüsünü yaradaraq seçiminizi asanlaşdıracaq.</p>
        },
        {
            key: '7',
            label: 'Çatdırılma xidmətləri təklif edirsiniz?',
            children: <p>Bəli, aldığınız məhsulların təhlükəsiz şəkildə istədiyiniz yerə daşınmasını təmin etmək üçün
            etibarlı çatdırılma xidmətləri təqdim edirik. Çatdırılma qiyməti məsafədən və sifariş ölçüsündən asılı
            olaraq dəyişə bilər.</p>
        },
        {
            key: '8',
            label: 'Satın almadan əvvəl nümunə sifarişi etmək mümkündür?',
            children: <p>Əlbəttə! Seçdiyiniz məhsuldan əmin olmaq və daha düzgün qərar verməyinizə kömək etmək
            üçün məhsullarımızın nümunələrini tələb edə bilərsiniz.</p>
        },
        {
            key: '9',
            label: 'Məhsulu aldıqdan sonra qaytarmaq vəya dəyişdirmək mümkündür?',
            children: <p>Əgər alışınız sizi qane etmirsə, geri qaytarma prosesini başlatmaq üçün 1 ay ərzində bizimlə
            əlaqə saxlamağənız lazımdır. Nəzərə alınmış vaxt ərzində məhsul dəyişdirilə vəya qaytarıla bilər.</p>
        },
        {
            key: '10',
            label: 'Böyük layihələri və ya toplu sifarişləri qəbul edə bilərsinizmi?',
            children: <p>Mütləq! Bizim irimiqyaslı layihələri idarə etmək və toplu sifarişlər üçün rəqabətli qiymət təklifi
            etmək imkanımız var. Xüsusi tələblərinizi müzakirə etmək üçün əlaqə saxlayın.</p>
        },
    ]
    return(
        <div className={styles.faq}>
            <h1>SUAL & CAVAB</h1>
            <Collapse items={questions}/>
        </div>
    )
}

export default Faq;