import React, { useEffect, useRef } from "react";
import styles from "./loading.module.css"

const Loading = () => {
    const square_ref = useRef(null);
    const squares = document.querySelectorAll(".square");

const gradientColors = [
  "#9e6b29",
  "#ad752b",
  "#bd7a24",
  "#cc8933",
  "#d49646",
  "#dba660",
  "#e3ba84",
  "#f0d1a8",
  "#f5e1c6",
];
    return(
        <div className={styles.body}>
            <div className={styles.spinner}>
                <div className={styles.square} style={{backgroundColor: "#9e6b29", animationDelay: "0s"}} id="s1"></div>
                <div className={styles.square} style={{backgroundColor: "#ad752b", animationDelay: "0.125s"}} id="s2"></div>
                <div className={styles.square} style={{backgroundColor: "#bd7a24", animationDelay: "0.250s"}} id="s3"></div>
                <div className={styles.square} style={{backgroundColor: "#cc8933", animationDelay: "0.375s"}} id="s4"></div>
                <div className={styles.square} style={{backgroundColor: "#d49646", animationDelay: "0.500s"}} id="s5"></div>
                <div className={styles.square} style={{backgroundColor: "#dba660", animationDelay: "0.625s"}} id="s6"></div>
                <div className={styles.square} style={{backgroundColor: "#e3ba84", animationDelay: "0.750s"}} id="s7"></div>
                <div className={styles.square} style={{backgroundColor: "#f0d1a8", animationDelay: "0.875s"}} id="s8"></div>
                <div className={styles.square} style={{backgroundColor: "#f5e1c6", animationDelay: "1s"}} id="s9"></div>
            </div>
        </div>
    )
}

export default Loading;