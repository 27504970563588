import React, { useEffect, useRef, useState } from "react";

import styles from "./slider.module.css";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
const SecondSlider = ({collectionData}) => {
    let _index = -1;
    let _common_amount = 0;
    let _current_index = 1;
    let ref = useRef()
    let [main_block_width, setMain_block_width] = useState(window.innerWidth > 1170 ? 450 : 350);

    
    collectionData.forEach(e => {
        _common_amount += e.collections.length
    })
    let _second_slider = [];

    collectionData.map((e1, index1) => {
        return(
            e1.collections.map((e2, index2) => {
                _second_slider.push(e2)
            })
            )
        })
    useEffect(() => {
        window.addEventListener('resize', () => {
            if(window.innerWidth >= 1170){
                setMain_block_width(450)
            }else{
                setMain_block_width(350)
            }
        })
    }, [])

    const moveToRight = () => {
        if((_common_amount - 3) == _current_index){
            _current_index = -1
        }
        _current_index++
        document.querySelector('.inside_block').style.left = _current_index * (-1 * main_block_width) + 'px'
        document.querySelector('.inside_block').style.width = main_block_width
        document.querySelectorAll('.bloc_secondary_switch').forEach((e) => {
            e.style.transform = `translateX(calc(${_current_index} * -100% - ${_current_index * 40}px))`
        })
    }
    const moveToLeft = () => {
        _current_index--
        if(_current_index < 0){
            _current_index = _common_amount - 3 ;
        }
        document.querySelector('.inside_block').style.left = _current_index * (-1 * main_block_width) + 'px'
        document.querySelectorAll('.bloc_secondary_switch').forEach((e) => {
            e.style.transform = `translateX(calc(${_current_index} * -100% - ${_current_index * 40}px))`
        })
    }
    setTimeout(() => {moveToLeft()}, 400)
    return(
        <section className={`${styles.second_slider}`} >
            <div className={styles.slider_blocks}>
                <h1 className={styles.title}>Kolleksiyalar</h1>
                <div ref={ref} className={styles.block_main}>
                    <div className={styles.navigation_bar}>
                        <div onClick={moveToLeft}></div>
                        <div onClick={moveToRight}></div>
                    </div>
                    <div className={`${styles.inside_block} inside_block`}>
                        {
                            collectionData.map((e1, index1) => {
                                return(
                                    e1.collections.map((e2, index2) => {
                                            _index++
                                            
                                        return <Collectionİtem key={index2} index2 = {index2} object={e2} main_obj={e1} index={_index} main_block_width={main_block_width}/>
                                    })
                                )
                            })
                        }
                    </div>
                </div>
                
                <div className={styles.secondary_blocks}>
                    {
                        _second_slider.slice(1).map((e,i) => {
                            _index++
                            return <SubCollection key={i} index={_index} object={e} i={i} main_block_width={main_block_width}/>
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default SecondSlider;

const Collectionİtem = ({index2, object, main_obj, index, main_block_width}) => {
    let [image, setImage] = useState('')
    let [loading, setLoading] = useState(true)
    async function getImage(){
        try{
            let res = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${object.name}`)
            let main = res.data.filter(e => e.includes('main'))
            let design = res.data.filter(e => e.includes('Design'))
            if(main.length){
                setImage(main[0])
            }else if(!main.length && design.length){
                setImage(design[0])
            }else setImage(res.data[0])
        }catch(err){
            console.log(err)
        }finally{
            setTimeout(() => {setLoading(false)}, 1000)
        }
    }
    useEffect(() => {
        setLoading(true)
        getImage()
    }, [object])
    if(loading){
        return(
            <div key={index2} style={{left: (index * main_block_width), width: main_block_width, backgroundPosition: "center", backgroundSize: "cover",height: '100%'}} className={styles.main_inside}>
                <div className={styles.inside_product}>
                    <h3></h3>
                    <p></p>
                </div>
            </div>
        )
    }
    return(
        <Link to={`/collection/${object._id}`}>
            <div key={index2} style={{left: (index * main_block_width), width: main_block_width, backgroundImage: `url('https://canaqqalaseramik.az:5500/slider/image/${object.name}/${image}')`, backgroundPosition: "center", backgroundSize: "cover",height: '100%'}} className={styles.main_inside}>
                <div className={styles.inside_product}>
                    <h3>{object.fancy}</h3>
                    <p>{main_obj.name}</p>
                </div>
            </div>
        </Link>  
    )
}

const SubCollection = ({index, object, i, main_block_width}) => {
    let [image, setImage] = useState('')
    let [loading, setLoading] = useState(true)
    async function getImage(){
        try{
            let res = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${object.name}`)
            let main = res.data.filter(e => e.includes('main'))
            let design = res.data.filter(e => e.includes('Design'))
            if(main.length && design.length){
                setImage(main[0])
            }else if(!main.length && design.length){
                setImage(design[0])
            }else setImage(res.data[0])
        }catch(err){
            console.log(err)
        }finally{
            setTimeout(() => {setLoading(false)}, 1000)
        }
    }
    useEffect(() => {
        getImage()
    }, [])
    useEffect(() => {
        setLoading(true)
        getImage()
    }, [object])
    if(loading) return <div style={{left: (index * main_block_width), backgroundColor: 'white', backgroundPosition: "center", backgroundSize: "150% 150%"}} className={`${styles.block_secondary} bloc_secondary_switch`}></div>
    return(
        
        <a href={`/collection/${object._id}`}>
            <div key={i} style={{left: (index * main_block_width), backgroundImage: `url('https://canaqqalaseramik.az:5500/slider/image/${object.name}/${image}')`, backgroundPosition: "center", backgroundSize: 'cover'}} className={`${styles.block_secondary} bloc_secondary_switch`}>
                <div className={styles.secondary_inside_product}>
                    <h3>{object.fancy}</h3>
                    <p>{object.country}</p>
                </div>
            </div>
        </a>
    )
}