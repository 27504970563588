import React, { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from "./catalog.module.css"
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import Loading from "../loading/loading";
import { Image } from 'antd';

//https://canaqqalaseramik.az:5500/product/6492e73913b2a22972b00e54

const Product = () => {
    const {id} = useParams(),
    [product, setProduct] = useState({}),
    [loading, setLoading] = useState(true),
    [images, setImages] = useState([]),
    [similarProducts, setSimilarProducts] = useState([])
    // const [isScrolled, setIsScrolled] = useState(false);

  
    useEffect(() => {
        async function getProduct(){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/product/${id}`)
                const similar = await axios.get('https://canaqqalaseramik.az:5500/products/all')
                let images = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${res.data.Ölkə}/${res.data.Çeşid}/${res.data.Ölçü}/${res.data.Nomenklatura}`)
                setImages(images.data)
                const filtered = similar.data.filter((item) => item.Tekstura == res.data.Tekstura)
                const first =Math.floor(Math.random() * (filtered.length / 2))
                const second =Math.floor(Math.random() * (filtered.length - 1)) + (filtered.length / 2)

                setSimilarProducts(filtered.slice(first, first + 4))
                setProduct(res.data)
                setTimeout(() => {setLoading(false)}, 1000)
            }catch(err){
                console.log(err)
            }
        }
        getProduct()
    }, [])
    const handleScroll = async () => {
        try{
            const product_res = await axios.get(`https://canaqqalaseramik.az:5500/product/${id}`)
            const res = await axios.put(`https://canaqqalaseramik.az:5500/product/update/${id}`, {views: product_res.data.views + 1})
        }catch(err){
            console.log(err)
        }
    };
    let functionCalled = false;
    function updateView() {
        const triggerHeight = 400;
        
        const scrollY = window.scrollY || window.pageYOffset;
        if (scrollY >= triggerHeight && !functionCalled) {
            handleScroll();
            functionCalled = true; 
        }
      }
      window.addEventListener("scroll", updateView);
    if(loading){
        return <div><Loading/></div>
    }
    return(
        <div className={styles.product}>
            <div className={styles.product_adv}>
                <div className={styles.product_props}>
                        <div>
                            <h4>Səthi</h4>
                            <div className={styles.inner_setting}>{product.Üzləmə}</div>
                        </div>
                        <div>
                            <h4>Çeşid</h4>
                            <div className={styles.inner_setting}>{product.Çeşid}</div>
                        </div>
                        <div>
                            <h4>Ölkə</h4>
                            <div className={styles.inner_setting}>{product.Ölkə}</div>
                        </div>
                        <div>
                            <h4>Tekstura</h4>
                            <div className={styles.inner_setting}>{product.Tekstura}</div>
                        </div>
                        <div>
                            <h4>Ölçü</h4>
                            <div className={styles.inner_setting}>{product.Ölçü}</div>
                        </div>
                </div>
            </div>
            <div className={styles.product_img} style={{
                                    backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${product.Ölkə}/${product.Çeşid}/${product.Ölçü}/${product.Nomenklatura}/${images[images.length - 1]}")`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundColor: 'inherit'
                                }}>
                <h1>{product.Tekstura}</h1>
                {product.stock ? <div style={{position: 'absolute', backgroundColor: '#4B6258', borderBottomLeftRadius: '10px',color: 'white', padding: '5px 0', width: '80px', right: 0, top: 0, textAlign: 'center'}}>STOKDA VAR</div> : <div style={{position: 'absolute', backgroundColor: '#f36258', borderBottomLeftRadius: '10px',color: 'white', padding: '5px 10px', width: '80px', right: 0, top: 0, textAlign: 'center'}}>STOKDA YOXDUR</div> }
            </div>
            <div className={styles.product_info}>
                <h1>{product.Nomenklatura}</h1>
                <div>
                    <TileImage product={product}/>
                </div>
            </div>
            {/* <div className={styles.seriler} style={{marginTop: "40px"}}>
                <h3>Bənzər kolleksiyalar</h3>
                <div className={styles.similar_products}>
                    {
                        similarProducts.map((item, index) => {
                            return (
                                <SimilarProduct key={index} product={item}/>
                            )
                        })
                    }
                </div>
            </div> */}
        </div>
    )
}

export default Product;

const SimilarProduct = ({product}) => {
    const [imgLoading, setImgLoading] = useState(true),
    [imgLink, setImageLink] = useState('')
    async function getImages (){
        try{
            let images = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${product.Ölkə}/${product.Çeşid}/${product.Ölçü}/${product.Nomenklatura}`)
            setImageLink(images.data[0])
        }catch(e){
            console.log(e)
        }finally{
            setTimeout(() => {setImgLoading(false)}, 1000)
        }
    }
    useEffect(() => {
        setImgLoading(true)
        getImages()
    }, [product])
    if(imgLoading) return(
        <div className={styles.similar_product} style={{
            backgroundColor: "white",
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative'
        }}></div>
    )
    return(
           
                <div className={styles.similar_product}  style={{
                        backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${product.Ölkə}/${product.Çeşid}/${product.Ölçü}/${product.Nomenklatura}/${imgLink}")`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative'
                    }}>
                    <a target="_blank" href={`/product/${product._id}`}>
                    {
                        <div style={{color: "black", backgroundColor: "#ffffff60", backdropFilter: "blur(10px)", width: "100%", position: 'absolute', left: '0', bottom: '0', padding: '20px 5px'}}>
                            <h3>{product.Nomenklatura}</h3>
                        </div>
                    }
                    </a>
                </div>
    )
} 

const TileImage = (props) => {
    const [images, setImages] = useState([]),
    [imgLoading, setImgLoading] = useState(true)
    useEffect(() => {
        async function getImages(){ 
            try{
                let images = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${props.product.Ölkə}/${props.product.Çeşid}/${props.product.Ölçü}/${props.product.Nomenklatura}`)
                setImages(images.data)
            }catch(err){
                console.log(err)
            }finally{
                setImgLoading(false)
            }
        }

        getImages()
    }, [])

    return(
        <div className={styles.images_gallery}>
            <Image.PreviewGroup
                preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
            {
                images.map((image, index) => {
                    let imgs = images.map((item, index) => `https://canaqqalaseramik.az:5500/product/image/${props.product.Ölkə}/${props.product.Çeşid}/${props.product.Ölçü}/${props.product.Nomenklatura}/${item}`)

                    console.log(imgs)
                    return(
                        <div key={index} className={styles.image_tile} style={{
                            margin: "10px",
                            display: "inline-block"
                        }}>
                            <Image
                                preview={{
                                mask: 'Baxmaq' 
                                }}
                                height={300}
                                src={`https://canaqqalaseramik.az:5500/product/image/${props.product.Ölkə}/${props.product.Çeşid}/${props.product.Ölçü}/${props.product.Nomenklatura}/${image}`}
                            />
                        </div>
                    )
                })
            }
            </Image.PreviewGroup>
        </div>
        )
}