import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFlip, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";


import styles from "./protfolio.module.css";

import _1 from "../assets/portfolio/partners/1.png"
import _2 from "../assets/portfolio/partners/2.png"
import _3 from "../assets/portfolio/partners/3.png"
import _4 from "../assets/portfolio/partners/4.png"
import _5 from "../assets/portfolio/partners/5.png"
import _6 from "../assets/portfolio/partners/6.png"
import _7 from "../assets/portfolio/partners/7.png"
import _8 from "../assets/portfolio/partners/8.png"
import _9 from "../assets/portfolio/partners/9.png"
import _10 from "../assets/portfolio/partners/10.png"
import _11 from "../assets/portfolio/partners/11.png"
import _12 from "../assets/portfolio/partners/12.png"
import _13 from "../assets/portfolio/partners/13.png"
import _14 from "../assets/portfolio/partners/14.png"
import _15 from "../assets/portfolio/partners/15.png"
import _16 from "../assets/portfolio/partners/16.png"
import _17 from "../assets/portfolio/partners/17.png"
import _18 from "../assets/portfolio/partners/18.jpg"
import _19 from "../assets/portfolio/partners/19.jpg"
import _20 from "../assets/portfolio/partners/20.png"
import _21 from "../assets/portfolio/partners/21.png"
import _22 from "../assets/portfolio/partners/22.png"
import _23 from "../assets/portfolio/partners/23.png"
import _24 from "../assets/portfolio/partners/24.png"
import _25 from "../assets/portfolio/partners/25.png"
import _26 from "../assets/portfolio/partners/26.png"
import _27 from "../assets/portfolio/partners/27.png"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Partners = () => {
    const location = useLocation().hash.slice(1)
    useEffect(() => {
        function scrollToPartners() {
            const partnersElement = document.getElementById(location);
            const partnersPosition = partnersElement.getBoundingClientRect().top;
            const startPosition = window.pageYOffset;
            const distance = partnersPosition - startPosition;
      
            const duration = 1000; // Длительность скролла (в миллисекундах)
            let start = null;
      
            // Функция анимации скролла
            function animateScroll(timestamp) {
              if (!start) start = timestamp;
              const progress = timestamp - start;
      
              const easeInOutQuad = (t) => t<.5 ? 2*t*t : -1+(4-2*t)*t;
      
              window.scrollTo(0, startPosition + distance * easeInOutQuad(progress / duration));
      
              if (progress < duration) {
                requestAnimationFrame(animateScroll);
              }
            }
      
            // Запуск анимации скролла
            requestAnimationFrame(animateScroll);
          }
          setTimeout(scrollToPartners, 1000)
    }, [location])
    const partners = [
        {
            img: _1
        },
        {
            img: _2
        },
        {
            img: _3
        },
        {
            img: _4
        },
        {
            img: _5
        },
        {
            img: _6
        },
        {
            img: _7
        },
        {
            img: _8
        },
        {
            img: _9
        },
        {
            img: _10
        },
        {
            img: _11
        },
        {
            img: _12
        },
        {
            img: _13
        },
        {
            img: _14
        },
        {
            img: _15
        },
        {
            img: _16
        },
        {
            img: _17
        },
        {
            img: _18
        },
        {
            img: _19
        },
        {
            img: _20
        },
        {
            img: _21
        },
        {
            img: _22
        },
        {
            img: _23
        },
        {
            img: _24
        },
        {
            img: _25
        },
        {
            img: _26
        },
        {
            img: _27
        },
    ]
    let converted_partners = []
    for(let i = 0; i < partners.length; i++){
        let obj = []
        obj.push(partners[i], partners[i+1])
        i++
        converted_partners.push(obj)
    }
    return(
        <div id="partner">
            <div className={styles.project_header}>
                <h1>Bizim Partnyorlar</h1>
            </div>
            <div className={`${styles.partners} container`}>
                
                <section>
                    <div className={styles.partners_header}>
                        <p className={styles.partners_header_text}>Uzun illərdir özəl və dövlət sektorlarından olan müxtəlif sifarişçilərin təmir və tikinti layihələrinin material tədarükçüsü kimi daim müraciət edəcəyi ünvan olaraq fəaliyyət göstəririk. Məhsullarımızın istifadə olunduğu yerlərin içərisinə yaşayış binaları, inzibati binalar, iaşə obyektləri, ticarət obyektləri, dəmir yolu vağzalları, hava limanları, supermarketlər və s. daxildir.</p>
                    </div>
                </section>
            
                <section>
                    <div className={styles.partners_blocks}>
                        {
                            partners.map((el, i) => {
                                return(
                                    <Swiper
                                        key={i}
                                        effect={"flip"}
                                        allowTouchMove={false}
                                        grabCursor={false}
                                        autoplay={{
                                        delay: Math.floor(Math.random() * 7000) + 3000,
                                        disableOnInteraction: false,
                                        }}
                                        loop={false}
                                        pagination={false}
                                        navigation={false}
                                        modules={[Autoplay, EffectFlip, Pagination, Navigation]}
                                        className={styles.mySwiper}
                                    >
                                                    <SwiperSlide>
                                                        <div style={{backgroundImage: `url(${el.img})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', height: '100%'}}></div>
                                                    </SwiperSlide>
                                                    <SwiperSlide>
                                                        <div style={{backgroundImage: `url(${el.img})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', height: '100%'}}></div>
                                                    </SwiperSlide>
                                                
                                    </Swiper>
                                )
                            })
                        }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Partners;