import React, { useEffect, useRef, useState } from "react";

import logo_mobile from '../assets/logo-mobile.png';
import logo from '../assets/logo.png';
import burger from '../assets/icons/burger.svg';
import home from '../assets/icons/home.svg'
import about_ico from '../assets/icons/about-us.svg';
import products_ico from '../assets/icons/products.svg';
import portfolio_ico from '../assets/icons/portfolio.svg';
import catalog_ico from '../assets/icons/catalog.svg';
import insta_ico from '../assets/icons/instagram_black.svg'
import facebook_ico from '../assets/icons/facebook.svg'
import tiktok_ico from '../assets/icons/tiktok.svg'
import search_ico from '../assets/icons/search.svg'

import styles from './nav.module.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import Loading from "../loading/loading";

const NavBar = ({sideBar, setSideBar, setActivateSearch, activateSearch}) => {
    let [searchInput, setSeacrhInput] = useState(''),
    [searchLoading, setSearchLoading] = useState(true),
    [foundProducts, setFoundProducts] = useState([]),
    [mobileSearch, setMobileSearch] = useState('')
    const [showResults, setShowResults] = useState(false);
    const searchInputRef = useRef();
    useEffect(() => {
      // Добавляем обработчик события "click" на весь документ
      document.addEventListener('click', handleDocumentClick);
      
      // Убираем обработчик события при размонтировании компонента
      return () => {
      document.removeEventListener('click', handleDocumentClick);
      };
  }, []);

  // Обработчик события "click" на документе
  const handleDocumentClick = (event) => {
      // Проверяем, является ли клик внутри инпута или его результатов поиска
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
      // Если клик был вне инпута и результатов поиска, скрываем результаты и очищаем инпут
      setShowResults(false);
      setMobileSearch('')
      }
  };
  const handleInputClick = (event) => {
      event.stopPropagation();
      setShowResults(true);
    };
    useEffect(() => {
        if(!activateSearch){
            setSeacrhInput('')
        }
        async function findProducts(){
            const res = await axios.get('https://canaqqalaseramik.az:5500/products/all')
            const filtered = res.data.filter((item) => {
                if(searchInput.length){
                    return item.Nomenklatura.toLowerCase().includes(searchInput.toLowerCase())
                }else if(mobileSearch.length){
                    return item.Nomenklatura.toLowerCase().includes(mobileSearch.toLowerCase())
                }
            })
            setFoundProducts(filtered.slice(0, 4))
            setTimeout(() => {setSearchLoading(false)}, 5000)
        }
        findProducts()
    }, [searchInput, activateSearch, mobileSearch])
    return(
        <div>
            <div className={styles.location_header}>
                <div className={styles.location_links}>
                    <a href="https://goo.gl/maps/syaXhjDnsooqKciw8" >Çanakkale Seramik – Xətai prospekti 43</a>
                    <a href="https://goo.gl/maps/e7KqAfd18Kk1DegE9" >Kale Seramik – Xətai prospekti 158</a>
                </div>
                <div className={styles.social_media}>
                    <a href="https://www.facebook.com/canaqqala.seramik.az?mibextid=LQQJ4d" ><img alt="" src={facebook_ico}/></a>
                    <a href="https://instagram.com/canaqqalaseramik?igshid=MzRlODBiNWFlZA==" ><img alt="" src={insta_ico}/></a>
                </div>
            </div>
            <div className={styles.navigation_block}>
            <nav className={styles.nav}>
                <div className={styles.logo}><a href="/"><img alt="" src={logo}/></a></div>
                <ul className={styles.menu_list}>
                    <div className={styles.mid_nav}>
                        <li>
                            <a href="/">
                            <img alt="" src={home}/>
                            <p>Əsas səhifə</p>
                            </a>
                        </li>
                        <li className={styles.dropdown_hover1}>
                            <img alt="" src={about_ico}/>
                            <p>Haqqımızda</p>
                            <ul className={`${styles.dropdown}`}>
                                <Link to="/tarix"><li>Tarixçə</li></Link>
                                <Link to="/ck-showroom/ck"><li>Çanakkale Seramik</li></Link>
                                <Link to="/k-showroom/kale"><li>Kale Seramik</li></Link>
                            </ul>
                        </li>
                        <li className={styles.dropdown_hover2}>
                            <img alt="" src={products_ico}/>
                            <p>Məhsullar <br/> və xidmətlər</p>
                            <ul className={`${styles.dropdown} ${styles.dropdown2}`}>
                                <a  href="/kataloq/KAFEL"><li>Kafel</li></a>
                                <a  href="/kataloq/METLAX"><li>Metlax</li></a>
                                <a  href="/kataloq/QRANİT"><li>Qranit</li></a>
                                <a  href="/kataloq/FLEX"><li>Flex</li></a>
                                <a  href="/kataloq/DEKOR"><li>Dekor</li></a>
                            </ul>
                        </li>
                        <li className={styles.dropdown_hover3}>
                            <img alt="" src={portfolio_ico}/>
                            <p>Porfolio</p>
                            <ul className={`${styles.dropdown} ${styles.dropdown3}`}>
                                <Link to="/portfolio#brend"><li>Brendlər</li></Link>
                                <Link to="/portfolio#partner"><li>Partnyorlar</li></Link>
                                <Link to="/portfolio#proyektler"><li>Proyektlər</li></Link>
                            </ul>
                        </li>
                    </div>
                    <div className={styles.search_side}>
                        <li>
                            <a href="/kataloq">
                                <img alt="" src={catalog_ico}/>
                                <p>Kataloq</p>
                            </a>
                        </li>
                        <li onClick={() => {setActivateSearch(!activateSearch)}} className={styles.nav_search}>
                            <img alt="" id="search" src={search_ico}/>
                            <p>Axtar</p>
                        </li>
                        {
                            <div className={styles.search_box} style={{opacity: activateSearch ? 1 : 0}}>
                                <div>
                                    <input id="search_box" type="text" placeholder="Axtar..." onChange={(e) => {setSeacrhInput(e.target.value)}} value={searchInput}/>
                                </div>
                                <div className={styles.found_box}>
                                    {
                                        searchLoading ?
                                        <div><Loading/></div>
                                        :   
                                        foundProducts.map((item, index) => {
                                            return <a key={index}  href={`/product/${item._id}`}><FoundProduct value={searchInput} tileObject={item}/></a>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </ul>
                </nav>
                <nav className={styles.nav_mobile}>
                    <Link to="/"><img className={styles.logo_mobile} src={logo_mobile} alt = ''/></Link>
                    <div className={styles.nav_social}>
                        <a href="https://www.facebook.com/canaqqala.seramik.az?mibextid=LQQJ4d" ><img alt="" src={facebook_ico}/></a>
                        <a href="https://instagram.com/canaqqalaseramik?igshid=MzRlODBiNWFlZA==" ><img alt="" src={insta_ico}/></a>
                        <img className={styles.burger_ico} src={burger} onClick={() => setSideBar(true)} alt=""/>
                    </div>
                    <div className={styles.mob_search}>
                        <div className={styles.mobile_main_search}>
                            <input onClick={handleInputClick} type="text" placeholder="Axtar..." ref={searchInputRef} onChange={(e) => {setMobileSearch(e.target.value)}} value={mobileSearch}/>
                            <div className={styles.found_box_mob}>
                                {
                                showResults && (
                                    foundProducts.map((item, i) => {
                                        return <MobileFound key={i} mobileSearch={mobileSearch}  tileObject={item}/>
                                    })
                                )
                                }
                            </div>
                            {/* <img alt="" width="20px" src={search_ico}/> */}
                            <Link to="/kataloq"><div className={styles.mobile_catalog_button}>Kataloq <img alt="" width="20px" src={catalog_ico}/></div></Link>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default NavBar;

const FoundProduct = ({value, tileObject}) => {
    const [image, setImage] = useState(''),
    [loading, setLoading] = useState(true)
    async function getImage() {
        try {
          const res = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}`);
          setImage(res.data[0]);
        } catch (err) {
          console.log(err);
        } finally {
          setTimeout(() => {setLoading(false)}, 1000)
        }
      }
      
      useEffect(() => {
        setLoading(true)
        getImage();
      }, [value, tileObject]); // Выполняется только один раз при монтировании компонента
      
      return (
        <div className={styles.found_item}>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {image && (
                <div
                  className={styles.found_item_img}
                  style={{
                    backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}/${image}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                ></div>
              )}
                <div className={styles.found_nomenklatura}>
                  <h3>{tileObject.Nomenklatura}</h3>
                </div>
            </>
          )}
        </div>
      );
}

const MobileFound = ({mobileSearch, tileObject}) => {
    const [image, setImage] = useState(''),
    [loading, setLoading] = useState(true)
    async function getImage() {
        try {
          const res = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}`);
          setImage(res.data[0]);
        } catch (err) {
          console.log(err);
        } finally {
          setTimeout(() => {setLoading(false)}, 1000)
        }
      }
      
      useEffect(() => {
        setLoading(true);
        getImage();
      }, [tileObject, mobileSearch]); // Выполняется только один раз при монтировании компонента
      
      return (
        <div className={styles.found_tile}>
          {loading ? (
            <div>Axtarıram</div>
          ) : (
            <a  href={`/product/${tileObject._id}`} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {image && (
                <div
                  className={styles.tile_img}
                  style={{
                    backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}/${image}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                ></div>
              )}
                <h3 className={styles.tile_nomenklatura}>
                  {tileObject.Nomenklatura}
                </h3>
            </a>
          )}
        </div>
      );
}
