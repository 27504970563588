import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getCheckTypes, getFilteredArray, getLazyLoading, getProductsAsync, lazyLoad } from "../actions";

import styles from "./catalog.module.css";
import search_ico from "../assets/icons/search.svg"
import close_ico from "../assets/icons/close.svg"
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import TileCard from "./tileCard";
import Loading from "../loading/loading";
import sekil8 from "../assets/contacts/ck-showroom/Sekil 8.jpg"
import { Button, Pagination } from "antd";


const itemsPerPage = 9;

const Catalog = () => {
    let dispatch = useDispatch(),
        productsList = useSelector((state) => state.products),
        // productsImage = useSelector((state) => state.images),
        [pages, setPages] = useState(0),
        [filteredArray, setFilteredArray] = useState([]),
        [isLoading, setIsLoading] = useState(true),
        [productsData, setProductsData] = useState([]),
        [showNext, setShowNext] = useState(true),
        [closed, setClosed] = useState(false),
        page = useSelector((state) => state.limit.page),
        filteredArrayState = useSelector(state => state.filteredArray),
        typeChecked = useSelector(state => state.filterTypes),
        [checkedCountry, setCheckedCountry] = useState([]),
        [checkedSize, setCheckedSize] = useState([]),
        [checkedCover, setCheckedCover] = useState([]),
        [checkedType, setCheckedType] = useState([]),
        [checkedTexture, setCheckedTexture] = useState([]),
        [searchValue, setSearchValue] = useState(''),
        [mobileSearchValue, setMobileSearchValue] = useState(''),
        // [checks, setChecks] = useState(false),
        [searchLoading, setSearchLoading] = useState(true),
        [foundProducts, setFoundProducts] = useState([]),
        [mobileFoundProducts, setMobileFoundProducts] = useState([]),
        lazyLoadingArr = useSelector(state => state.lazyLoading),
        [lazyIndex, setLazyIndex] = useState(2),
        [locationPath, setLocationPath] = useState([])
        const {style_param} = useParams()
        const [showResults, setShowResults] = useState(false);
        const searchInputRef = useRef();

        

        useEffect(() => {
            // Добавляем обработчик события "click" на весь документ
            document.addEventListener('click', handleDocumentClick);
            
            // Убираем обработчик события при размонтировании компонента
            return () => {
            document.removeEventListener('click', handleDocumentClick);
            };
        }, []);

        // Обработчик события "click" на документе
        const handleDocumentClick = (event) => {
            // Проверяем, является ли клик внутри инпута или его результатов поиска
            if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
            // Если клик был вне инпута и результатов поиска, скрываем результаты и очищаем инпут
            setShowResults(false);
            setSearchValue('');
            setMobileSearchValue('')
            }
        };
        const handleInputClick = (event) => {
            event.stopPropagation();
            setShowResults(true);
          };
    const cancelTokenSource = axios.CancelToken.source();

    const filter_state = () => {
        if(window.innerWidth >= 1360) {
            setClosed(true)
            document.body.style.overflowY = 'inherit'
            document.body.style.height = 'inherit'
        } else {
            setClosed(false)
        };
        if(closed){
            return styles.opened_bg
        }else {
            return styles.closed_bg
        }
    }
    window.addEventListener('resize', () => {filter_state()})
    if(closed){
        document.body.style.overflowY = 'hidden'
        document.body.style.height = '100%'
    }else{
        document.body.style.overflowY = 'inherit'
        document.body.style.height = 'inherit'
    }

    const location = useLocation().pathname.split("/")
    const categories = ["Çöl Məkan", "İç Məkan", "Ən çox satılan", "YENI KOLLEKSIYA", "KALESINTERFLEX", "fasad", "taxta"]
    const types_categorie = ["METLAX", "KAFEL", "DEKOR", "FLEX", "QRANİT"]
    
    useEffect(() => {
        setLocationPath(location)
        dispatch({type: "STOP_INTERVAL"})
        filter_state()
        dispatch(getProductsAsync(page))
        async function fetchData(){
            try{
                const res = await axios.get('https://canaqqalaseramik.az:5500/products/all', {
                    cancelToken: cancelTokenSource.token
                })
                // res.data.forEach(async (e) => {
                //     dispatch(getImagesAsync(e))
                // })
                setProductsData(res.data)
                setPages(res.data.length / 9)
                setTimeout(() => {setIsLoading(false)}, 500)
            }catch(err){
                console.log(err)
            }
        }
        fetchData()

        return () => {
            cancelTokenSource.cancel('Canceled')
        }
        
    }, [page])

    const getElements = async () => {
        document.documentElement.scrollTop = document.documentElement.scrollHeight - 1024
        if(productsData.length - 1 == productsList.length){
            setShowNext(false)
        }
        dispatch({type: 'INCR'})
    }
    
    const handleCountry = (e) => {
        if(e.target.checked){
            setCheckedCountry([...checkedCountry, e.target.value])
            dispatch(getCheckTypes([e.target.value], null))
        }else{
            setCheckedCountry(checkedCountry.filter(item => item != e.target.value))
            dispatch(getCheckTypes(typeChecked, e.target.value))
        }
    }
    const handleType = (e) => {
        if(e.target.checked){
            setCheckedType([...checkedType, e.target.value])
            dispatch(getCheckTypes([e.target.value], null))
        }else{
            setCheckedType(checkedType.filter(item => item != e.target.value))
            dispatch(getCheckTypes(typeChecked, e.target.value))
        }
    }
    const handleTexture = (e) => {
        if(e.target.checked){
            setCheckedTexture([...checkedTexture, e.target.value])
            dispatch(getCheckTypes([e.target.value], null))
        }else{
            setCheckedTexture(checkedTexture.filter(item => item != e.target.value))
            dispatch(getCheckTypes(typeChecked, e.target.value))
        }
    }
    const handleCover = (e) => {
        if(e.target.checked){
            setCheckedCover([...checkedCover, e.target.value])
            dispatch(getCheckTypes([e.target.value], null))
        }else{
            setCheckedCover(checkedCover.filter(item => item != e.target.value))
            dispatch(getCheckTypes(typeChecked, e.target.value))
        }
    }

    const handleSize = (e) => {
        if(e.target.checked){
            setCheckedSize([...checkedSize, e.target.value])
            dispatch(getCheckTypes([e.target.value], null))
        }else{
            setCheckedSize(checkedSize.filter(item => item != e.target.value))
            dispatch(getCheckTypes(typeChecked, e.target.value))
        }
    }

    const filtereDataArray = () => {
        const filteredArray = productsData.filter(item => {
            const isCountryMatched = checkedCountry.length > 0 ? checkedCountry.includes(item.Ölkə) : true
            const isSizeMatched = checkedSize.length > 0 ? checkedSize.includes(item.Ölçü) : true
            const isPriceMatched = checkedType.length > 0 ? checkedType.includes(item.Çeşid) : true
            const isTextureMatched = checkedTexture.length > 0 ? checkedTexture.includes(item.Tekstura) : true
            const isCoverMactched = checkedCover.length > 0 ? checkedCover.includes(item.Üzləmə) : true

            return isCountryMatched & isSizeMatched & isPriceMatched & isTextureMatched & isCoverMactched
        })
        if(typeChecked.length){
            dispatch(getFilteredArray(filteredArray))
        }else{
            dispatch(getFilteredArray([]))
        }
        setFilteredArray(filteredArray)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const letLazyLoad = () => {
        dispatch(getLazyLoading(filteredArrayState.slice(0, 9)))
    }
    useEffect(() => {   
        letLazyLoad()
    }, [filteredArray])
    useEffect(() => {
        filtereDataArray()
        setCurrentPage(1)
    }, [checkedCountry, checkedType, checkedSize, checkedTexture, checkedCover])


    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page) => {
        window.scrollTo(170, 170)
        setCurrentPage(page);
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = filteredArrayState.slice(startIndex, endIndex);

    const renderProducts = () => {
        if(typeChecked.length){ 
            if(filteredArray.length){
                return(
                    <>
                        {
                            currentPageData.map((e, i) => {
                                return <TileCard tileObject={e} key={e._id} i={i}/>
                            }) 
                        }
                        {
                           !(lazyLoadingArr.length == filteredArray.length) ? 
                            <div className={styles.next_btn}>
                                <Pagination showSizeChanger={false} current={currentPage} total={filteredArrayState.length} onChange={handlePageChange} pageSize={itemsPerPage}/>
                                {/* <button onClick={() => {
                                    document.documentElement.scrollTop = document.documentElement.scrollHeight - 1024
                                dispatch(lazyLoad(lazyLoadingArr, filteredArray, lazyIndex))
                                setLazyIndex(lazyIndex + 1)
                                }}>Növbəti</button>  */}
                            </div>
                            : <></>    
                        }
                    </>
                )
            }else return <h1 style={{
                textAlign: 'center',
                width: '100%',
                fontWeight: '200',
                position: 'absolute',
                backgroundColor: "white",
                padding: '10px 20px',
                borderRadius: '5px',
                boxShadow: '1px 1px 6px #00000040'
                
            }}>Filterlərə uyğun məhsul yoxdu</h1>
        }else if(locationPath[1] === "kataloq" && locationPath.length == 2){
            return (
                <>  
                    {
                        productsList.map((e, i) => {
                            return <TileCard tileObject={e} key={e._id} i={i}/>
                        }) 
                    }
                    <div className={styles.next_btn}>
                        <Pagination onChange={(page) => {
                            dispatch({type: 'INCR', page: page})
                            window.scrollTo(170, 170)
                        }}  defaultCurrent={1} total={Math.round(pages) * 10} showSizeChanger={false}/>
                        {/* <button onClick={getElements} disabled={!showNext}>{showNext ? 'Növbətis' : 'damnnn'}</button> */}
                    </div>
                </>
            )
            
            }else if (locationPath[1] === "kataloq" && categories.includes(locationPath[2])) {
                return <ToStyle data={style_param}/>
            }else if(locationPath[1] === "kataloq" && types_categorie.includes(locationPath[2])){
                return <ToStyleType data={style_param}/>
            }
    }
    useEffect(() => {
        
        async function findProducts(){
            const res = await axios.get('https://canaqqalaseramik.az:5500/products/all')
            const filtered = res.data.filter((item) => {
                return item.Nomenklatura.toLowerCase().includes(searchValue.toLowerCase())
            })
            const mobileFiltered = res.data.filter((item) => {
                return item.Nomenklatura.toLowerCase().includes(mobileSearchValue.toLowerCase())
            })
            if(searchValue.length){
                setFoundProducts(filtered.slice(0, 4))
            }else{
                setFoundProducts([])
            }
            if(mobileSearchValue.length){
                setMobileFoundProducts(mobileFiltered.slice(0,4))
            }else{
                setMobileFoundProducts([])
            }

            setTimeout(() => {setSearchLoading(false)}, 500)
        }
        findProducts()
    }, [searchValue, mobileSearchValue])
    if(window.innerWidth >= 1170) {
        document.body.style.overflowY = 'inherit'
        document.body.style.height = 'inherit'
    }
    if(isLoading){
        return <div><Loading/></div>
    }
    return( 
        <div className={styles.catalog}>
            <div className={styles.catalog_header} style={{
                backgroundImage: `url("${sekil8}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white'
            }}>
                <h1>
                    Kataloq
                </h1>
            </div>
            <div className={styles.catalog_body}>
                <div className={`${styles.filter} ${closed ? styles.opened_bg : styles.closed_bg}`}>
                    <div className={styles.filter_search}>
                        <h2 onClick={() => {
                            setClosed(true)
                            }}>Filterlər</h2>
                        <div className={styles.search}>
                            <input type="text" className={styles.search_input} ref={searchInputRef} value={mobileSearchValue} onChange={(e) => setMobileSearchValue(e.target.value)}/>
                            <img src={search_ico} alt="search ico" className={styles.search_btn}/>
                            <div className={styles.mobile_seacrh_box} style={{height: mobileSearchValue.length ? "inherit" : "0", opacity: mobileSearchValue.length ? "1" : "0", zIndex: '1'}}>
                                {
                                    searchLoading ? 
                                    <div>
                                        <Loading/>
                                    </div>
                                    :
                                    mobileFoundProducts.map((item, index) => {
                                        return <MobileFound tileObject={item} index={index} mobileSearch={mobileSearchValue}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.filters_params} ${closed ? styles.opened_filter : styles.closed_filter}`}>
                        <div className={styles.filter_header}>
                            <img alt="close ico" className={styles.close_btn} src={close_ico} onClick={() => {
                                setClosed(false)
                                }}/>
                            <h3>Filterlər</h3>
                        </div>
                        <div className={styles.params_box}>
                            <div className={styles.params_item}>
                                <input defaultChecked className={styles.collapsible} type="checkbox" id="collapsible1"/>
                                <label className={styles.param_type} htmlFor="collapsible1">Ölkə</label>
                                <div className={styles.params}>
                                    <input id="item5" type="checkbox" value="Azerbaycan" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item5">Azərbaycan</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item4" type="checkbox" value="TÜRKİYƏ" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item4">Türkİyə</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item0" type="checkbox" value="ÇİN" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item0">Çİn</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item1" type="checkbox" value="İRAN" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item1">İran</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item2" type="checkbox" value="ÖZBƏKİSTAN" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item2">Özbəkİstan</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item3" type="checkbox" value="RUSİYA" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item3">Rusİya</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item4" type="checkbox" value="TÜRKİYƏ" onChange={(e) => {handleCountry(e)}}/>
                                    <label htmlFor="item4">Türkİyə</label>
                                </div>
                            </div>
                            <div className={styles.params_item}>
                                <input defaultChecked className={styles.collapsible} type="checkbox" id="collapsible2"/>
                                <label className={styles.param_type} htmlFor="collapsible2">Çeşid</label>
                                <div className={styles.params}>
                                    <input id="item5" type="checkbox" value="QRANİT" onChange={(e) => {handleType(e)}}/>
                                    <label htmlFor="item5">Qranİt</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item6" type="checkbox" value="DEKOR" onChange={(e) => {handleType(e)}}/>
                                    <label htmlFor="item6">Dekor</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item7" type="checkbox" value="KAFEL" onChange={(e) => {handleType(e)}}/>
                                    <label htmlFor="item7">Kafel</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item8" type="checkbox" value="METLAX" onChange={(e) => {handleType(e)}}/>
                                    <label htmlFor="item8">Metlax</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item9" type="checkbox" value="FLEX" onChange={(e) => {handleType(e)}}/>
                                    <label htmlFor="item9">Flex</label>
                                </div>
                            </div>
                            <div className={styles.params_item}>
                                <input defaultChecked className={styles.collapsible} type="checkbox" id="collapsible5"/>
                                <label className={styles.param_type} htmlFor="collapsible5">Səthi</label>
                                <div className={styles.params}>
                                    <input id="item10" type="checkbox" value="Parlaq" onChange={(e) => {handleCover(e)}}/>
                                    <label htmlFor="item10">Parlaq</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item11" type="checkbox" value="Mat" onChange={(e) => {handleCover(e)}}/>
                                    <label htmlFor="item11">Mat</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item12" type="checkbox" value="Sugar Effect" onChange={(e) => {handleCover(e)}}/>
                                    <label htmlFor="item12">Sugar Effect</label>
                                </div>
                            </div>
                            <div className={styles.params_item}>
                                <input defaultChecked className={styles.collapsible} type="checkbox" id="collapsible4"/>
                                <label className={styles.param_type} htmlFor="collapsible4">Tekstura</label>
                                <div className={styles.params}>
                                    <input id="item13" type="checkbox" value="Mərmər" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item13">Mərmər</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item14" type="checkbox" value="Dekorativ" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item14">Dekorativ</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item15" type="checkbox" value="Kərpic" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item15">Kərpİç</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item16" type="checkbox" value="Monocolor" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item16">Monocolor</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item17" type="checkbox" value="Beton" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item17">Beton</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item18" type="checkbox" value="Taxta" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item18">Taxta</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item19" type="checkbox" value="Terrazzo" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item19">Terrazzo</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item20" type="checkbox" value="Daş" onChange={(e) => {handleTexture(e)}}/>
                                    <label htmlFor="item20">Daş</label>
                                </div>
                            </div>
                            <div className={styles.params_item}>
                                <input defaultChecked className={styles.collapsible} type="checkbox" id="collapsible3"/>
                                <label className={styles.param_type} htmlFor="collapsible3">Ölçü</label>
                                <div className={styles.params}>
                                    <input id="item21" type="checkbox" value="7.5X30" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item21">7.5x30</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item22" type="checkbox" value="10X20" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item22">10x20</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item52" type="checkbox" value="10X30" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item52">10x30</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item23" type="checkbox" value="15X60" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item23">15x60</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item24" type="checkbox" value="15X90" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item24">15x90</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item25" type="checkbox" value="19.7X19.7" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item25">19.7x19.7</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item26" type="checkbox" value="20X20" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item26">20x20</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item27" type="checkbox" value="20X50" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item27">20x50</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item28" type="checkbox" value="20X120" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item28">20x120</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item29" type="checkbox" value="25X40" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item29">25x40</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item30" type="checkbox" value="25X60" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item30">25x60</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item31" type="checkbox" value="25X50" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item31">25x50</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item32" type="checkbox" value="25X75" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item32">25x75</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item33" type="checkbox" value="30X30" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item33">30x30</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item34" type="checkbox" value="30X50" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item34">30x50</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item35" type="checkbox" value="30X40" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item35">30x40</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item36" type="checkbox" value="30X90" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item36">30x90</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item37" type="checkbox" value="30X60" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item37">30x60</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item38" type="checkbox" value="40X40" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item38">40x40</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item120" type="checkbox" value="40X40" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item120">41x120</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item39" type="checkbox" value="45X45" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item39">45x45</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item40" type="checkbox" value="60X60" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item40">60x60</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item41" type="checkbox" value="60X120" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item41">60x120</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item42" type="checkbox" value="100X300" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item42">100x300</label>
                                </div>
                                <div className={styles.params}>
                                    <input id="item43" type="checkbox" value="120X280" onChange={(e) => {handleSize(e)}}/>
                                    <label htmlFor="item43">120x280</label>
                                </div>
                            </div>
                            {typeChecked.length ? 
                                <div className={styles.axtar}>
                                    <Button onClick={() => {setClosed(false)}}>Axtar</Button>
                                </div> : ''
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.item_side}>
                    <div className={styles.search_desktop}>
                        <input onClick={handleInputClick} ref={searchInputRef} type="text" className={styles.search_input} onChange={(e) => setSearchValue(e.target.value)}/>
                        <img src={search_ico} alt="search ico" className={styles.search_btn}/>
                        <div className={styles.seacrh_box} style={{height: searchValue.length ? "inherit" : "0", opacity: searchValue.length ? "1" : "0", zIndex: '1'}}>
                            {
                                showResults && (
                                    searchLoading ? 
                                        <div>
                                            <Loading/>
                                        </div>
                                        :
                                        foundProducts.map((item, index) => {
                                            return <FoundProduct key={item._id} value={searchValue} tileObject={item}/>
                                        })
                                )
                            }
                        </div>
                    </div>
                    <div className={styles.items}>
                        {
                            renderProducts()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Catalog;

const ToStyle = ({data}) => {
    const [products, setProducts] = useState([]),
    [lazyProducts, setLazyProducts] = useState([]),
    [lazyIndex, setLazyIndex] = useState(1);
    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page) => {
        window.scrollTo(170, 170)
        setCurrentPage(page);
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = products.slice(startIndex, endIndex);

    
    useEffect(() => {
        async function getData(){
            try{
                const res = await axios.get('https://canaqqalaseramik.az:5500/products/all')
                let filtered  = res.data.filter(e => {
                    let style = e['Zovqe gore'] ? e['Zovqe gore'].toLowerCase() : 'NONE'
                    let r = data.toLowerCase()
                    return style.includes(r)
                })
                const turkeyObjects = filtered.filter(item => item.Ölkə == 'TÜRKİYƏ');
                const otherObjects = filtered.filter(item => item.Ölkə != 'TÜRKİYƏ');
                const filteredData = [...turkeyObjects, ...otherObjects];
                setProducts(filteredData)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    }, [])
    useEffect(() => {
        nextLazy()
    }, [products])
    const nextLazy = () => {
        document.documentElement.scrollTop = document.documentElement.scrollHeight - 1024
        const lazied = products.slice(lazyProducts.length, lazyIndex * 9)
        setLazyProducts(prev => [...prev, ...lazied])
        setLazyIndex(lazyIndex+1)
    }
    return <>
        {currentPageData.map((element, i) => <TileCard key={element._id} tileObject={element} i={i}/>)}
        <div className={styles.next_btn}>
            <Pagination current={currentPage} total={products.length} pageSize={itemsPerPage} onChange={handlePageChange} showSizeChanger={false}/>
            {/* <button onClick={nextLazy} style={{visibility: lazyProducts.length < products.length ? 'inherit' : 'hidden' }}>Növbəti</button> */}
        </div>
    </>
}

const ToStyleType = ({data}) => {
    const [products, setProducts] = useState([]),
    [lazyProducts, setLazyProducts] = useState([]),
    [lazyIndex, setLazyIndex] = useState(1);

    
    const itemsPerPage = 9;
    const [currentPage, setCurrentPage] = useState(1);
    const handlePageChange = (page) => {
        window.scrollTo(170, 170)
        setCurrentPage(page);
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = products.slice(startIndex, endIndex);

    useEffect(() => {
        async function getData(){
            try{
                const res = await axios.get('https://canaqqalaseramik.az:5500/products/all')
                let filtered = res.data.filter(e => e.Çeşid == data)
                const turkeyObjects = filtered.filter(item => item.Ölkə == 'TÜRKİYƏ');
                const otherObjects = filtered.filter(item => item.Ölkə != 'TÜRKİYƏ');
                const filteredData = [...turkeyObjects, ...otherObjects];
                setProducts(filteredData)
            }catch(err){
                console.log(err)
            }
        }
        getData()
    }, [])
    // useEffect(() => {
    //     nextLazy()
    // }, [products])
    // const nextLazy = () => {
    //     document.documentElement.scrollTop = document.documentElement.scrollHeight - 1024
    //     const lazied = products.slice(lazyProducts.length, lazyIndex * 9)
    //     setLazyProducts(prev => [...prev, ...lazied])
    //     setLazyIndex(lazyIndex+1)
    // }
    return (
        <>
            {currentPageData.map((element, i) => {return <TileCard key={element._id} tileObject={element} i={i}/>})}
            <div className={styles.next_btn}>
                <Pagination current={currentPage} total={products.length} pageSize={itemsPerPage} onChange={handlePageChange} showSizeChanger={false}/>
                {/* <button onClick={nextLazy}>Növbəti</button> */}
            </div>
        </>
    )
}

const FoundProduct = ({value, tileObject}) => {
    const [image, setImage] = useState(''),
    [loading, setLoading] = useState(true)
    async function getImage() {
        try {
          const res = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}`);
          
            let main = res.data.filter(e => e.includes('main'))
            let design = res.data.filter(e => e.includes('Design'))
            if(main.length){
                setImage(main[0])
            }else if(!main.length && design.length){
                setImage(design[0])
            }else setImage(res.data[0])
        } catch (err) {
            console.log(err);
            return
        } finally {
            setTimeout(() => {setLoading(false)}, 1000)
        }
      }
      
      useEffect(() => {
        setLoading(true)
        getImage();
      }, [value, tileObject]); // Выполняется только один раз при монтировании компонента
      
      return (
        <div className={styles.find_item}>
          {loading ? (
            <div>Qözləyin...</div>
          ) : (
            <>
              {image && (
                <div
                  className={styles.find_item_image}
                  style={{
                    backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}/${image}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                ></div>
              )}
              <a target="_blank" href={`/product/${tileObject._id}`}>
                <div className={styles.find_item_title}>
                  <h3>{tileObject.Nomenklatura}</h3>
                </div>
              </a>
            </>
          )}
        </div>
      );
}


const MobileFound = ({mobileSearch, tileObject}) => {
    const [image, setImage] = useState(''),
    [loading, setLoading] = useState(true)
    async function getImage() {
        try {
          const res = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}`);
          
            let main = res.data.filter(e => e.includes('main'))
            let design = res.data.filter(e => e.includes('Design'))
            if(main.length){
                setImage(main[0])
            }else if(!main.length && design.length){
                setImage(design[0])
            }else setImage(res.data[0])
        } catch (err) {
            console.log(err);
            return
        } finally {
            setTimeout(() => {setLoading(false)}, 1000)
        }
      }
      useEffect(() => {
        setLoading(true)
        getImage();
      }, [mobileSearch, tileObject]);
    return(
       <div className={styles.find_item_mobile}>
          {loading ? (
            <div>Qözləyin...</div>
          ) : (
            <>
              {image && (
                <div
                  className={styles.find_item_image}
                  style={{
                    backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}/${image}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minWidth: '50px'
                  }}
                ></div>
              )}
              <a style={{marginLeft: '10px'}} className={styles.find_item_title} target="_blank" href={`/product/${tileObject._id}`}>
                  {tileObject.Nomenklatura}
              </a>
            </>
          )}
        </div>
    )
}
