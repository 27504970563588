import getImagesReducer from "./get_images_reducer";
import { combineReducers } from "redux";
import productListReducer from "./product_list_reducer";
import limitiReducer from "./limit_reducer";
import getPortfolioReducer from "./get_portfolio_reducer";
import intervalReducer from "./intervalReducer";
import getProjectImagesReducer from "./project_images";
import getTypesReducer from "./get_types_reducer";
import getFilteredArrayReducer from "./get_filtered_reducer";
import getSliderImagesReducer from "./slider_images";
import lazyLoadingReducer from "./lazyLoadingReducer";
import getTileImageReducer from "./get_tile_image_reducer";

const reducers = combineReducers({
    images: getImagesReducer,
    products: productListReducer,
    limit: limitiReducer,
    portfolio: getPortfolioReducer,
    interval: intervalReducer,
    projectImages: getProjectImagesReducer,
    sliderImages: getSliderImagesReducer,
    filterTypes: getTypesReducer,
    filteredArray: getFilteredArrayReducer,
    lazyLoading: lazyLoadingReducer,
    tileImage: getTileImageReducer
})

export default reducers;