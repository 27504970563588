import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
  
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import styles from "./slider.module.css";


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import axios from "axios";
import Loading from "../../loading/loading";

const FirstSlider = () => {
    let [sliderImg, setImgSlider] = useState([])
    let [loaded, setLoaded] = useState(false)
    useEffect(() => {
        async function getImages(){
            try{
                const response = await axios.get('https://canaqqalaseramik.az:5500/slider/all_items')
                setImgSlider(response.data)
                setTimeout(() => {setLoaded(true)}, 500)
            }catch(err){
                console.log(err)
            }
        }

        getImages()
    }, [])
    let desktop_slider = []
    for(let i = 0; i < sliderImg.length; i++) {
        let arr = [];
        arr.push(sliderImg[i], sliderImg[i+1])
        i += 1
        desktop_slider.push(arr);
    }
    if(!loaded){
        return <div className={styles.slider_one}><Loading/></div>
    }
    return(
            <>
                <div id="to_top" className={styles.slider_one}>
                    <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                    }}
                    pagination={false}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className={styles.mySwiper_mobile}
                    >
                        {
                            sliderImg.map((item, id) => {
                                return(
                                    <SwiperSlide key={id}>
                                        <SliderItem product={item}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </>
    )
}

export default FirstSlider;

const SliderItem = ({product}) => {
    let [imgLink, setImgLink] = useState('')
    let [load, setLoad] = useState(true)
    useEffect(() => {
        async function getImage(){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${product.Nomenklatura}`)
                setImgLink(res.data[0])
            }catch(err){
                console.log(err)
            }finally{
                setLoad(false)
            }
        }
        getImage()
    }, [])
    if(load) return <div className={styles.mobile_slider} style={{ backgroundColor: 'white', height: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: "100%"}}></div>
    return(
        <a href={`/collection/${product._id}`}>
            <div className={styles.mobile_slider} style={{ backgroundImage: `url("https://canaqqalaseramik.az:5500/slider/image/${product.Nomenklatura}/${imgLink}") `, height: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: "100%"}}>
                <h3>{product.Nomenklatura}</h3>
            </div>
        </a>
    )
}