import React from "react";

import styles from "./protfolio.module.css"
import layer_canakkale from "../assets/portfolio/brands/Canakkale.jpg"
import layer_bien from "../assets/portfolio/brands/Bien.jpg"
import layer_decovita from "../assets/portfolio/brands/Decovita.jpg"
import layer_veli from "../assets/portfolio/brands/Veli.jpg"
import layer_qua from "../assets/portfolio/brands/Qua.jpg"
import layer_volqoqrad from "../assets/portfolio/brands/Volqoqrad.jpg"
import layer_eefa from "../assets/portfolio/brands/EEFA.jpg"
import layer_prime from "../assets/portfolio/brands/Prime.jpg"
import layer_fakhar from "../assets/portfolio/brands/FAKHAR.jpg"
import layer_ceramara from "../assets/portfolio/brands/Ceramara.jpg"
import block_bg from "../assets/portfolio/brands-bg.jpg"

import eefa_logo from "../assets/portfolio/brands/logos/eefa.png"
import akgun_logo from "../assets/portfolio/brands/logos/akgun.png"
import bien_logo from "../assets/portfolio/brands/logos/bien.png"
import decovita_logo from "../assets/portfolio/brands/logos/decovita.png"
import fakhar_logo from "../assets/portfolio/brands/logos/fakhar.png"
import kale_logo from "../assets/portfolio/brands/logos/kale.png"
import majorca_logo from "../assets/portfolio/brands/logos/majorca.png"
import qua_logo from "../assets/portfolio/brands/logos/qua.png"

const Brands = () => {
    const brands_data = [
        {
            logo: kale_logo,
            img: layer_canakkale,
            title: 'Çanakkale Seramik / KALE',
            about: "Keramika sənayesinin qurulmasına öncülük edən Kaleseramik, Türkiyə torpaqlarında istehsal edən və bu torpaqlardan qazandıqlarını sərmayə qoyan 65 illik köklü bir şirkətdir. Bir neçə onillikləri əhatə edən zəngin tarixə malik şirkət dünyanım aparıcı keramik plitələ istehsalçılarına öncülük etməyə davam edir!"
        },
        {
            logo: bien_logo,
            img: layer_bien,
            title: 'BİEN',
            about: "Bien Seramik 5 qitə və 55-dən çox ölkəyə keramika, çini, şüşə mozaika və santexnika məhsulları ixrac edən Türkiyənin mükafatlı keramika şirkətidir."
        },
        {
            logo: decovita_logo,
            img: layer_decovita,
            title: 'DECOVİTA',
            about: "Decovita Seramik, 2015-ci ildə qurduğu zamandan ənənə və müasirliyi üstün dizayn və keyfiyyət anlayışı ilə birləşdirərək müştərilərə zəngin çeşiddə keyfiyyətli məhsulları təqdim edərək, Türkiyənin dünya üzrə ən tanınmış şirkətlərindən birinə qısa zamanda çevrilmişdir."
        },
        {
            logo: akgun_logo,
            img: layer_veli,
            title: 'Akgün Seramik',
            about: "“Hər işində mükəmməllik” şüarı ilə daim böyüyən və inkişaf edən Akgün Şirkətlər Qrupu, 16 fabriki ilə 45 ölkəyə ixrac edərək sektorun liderlərindən birinə çevrilmişdir."
        },
        {
            logo: qua_logo,
            img: layer_qua,
            title: 'QUA',
            about: "QUA Granite döşəmə örtüyü sektorunda ən yüksək əlavə dəyərə malik məhsul qrupu olan texniki qranit və şirli qranit (qranit kafel) istehsalı istiqamətində fəaliyyətini davam etdirən ən böyük fabriklərdən birinə malikdir və yeni açılan istehsal xətləri ilə artıq illik gücünü 41 milyon m²-ə çatdırmışdır."
        },
        {
            logo: '',
            img: layer_volqoqrad,
            title: 'Волгоградский Керамический Завод',
            about: '"Volqoqrad Keramika Zavodu" ASC daxili divar və döşəmə örtükləri, dekorativ elementlər, çini daş məmulatları və sanitar keramika üçün şirli keramik plitələr istehsal edən Rusiyanın aparıcı istehsalçılarından biridir.'
        },
        {
            logo: eefa_logo,
            img: layer_eefa,
            title: 'EEFA',
            about: "EEFA Ceram şirkəti dünyanın ən son texnologiyasından, italyan maşınlarından, rəqəmsal çapdan, kafel və keramika sənayesində ixtisaslı işçi heyətindən istifadə edərək şirli divar plitələri, döşəmə keramika, keramoqranit, dekorativ keramika istehsal edir."
        },
        {
            logo: '',
            img: layer_prime,
            title: 'Prime Ceramic',
            about: '"Prime Ceramics" LTD keramik plitələrin istehsalı və satışı ilə məşğul olan Özbəkistan şirkətidir. Şirkətin zavodu Səmərqənd şəhərində yerləşir və bu günə qədər Özbəkistanda ən yüksək texnologiyalı müəssisədir.'
        },
        {
            logo: fakhar_logo,
            img: layer_fakhar,
            title: 'FAKHAR',
            about: ""
        },
        {
            logo: '',
            img: layer_ceramara,
            title: 'Ceramara',
            about: "Ceramara kimi tanınan Ceramara Industries Co. İranda ən böyük dekorativ plitələr və keramika istehsalçısıdır. Şirkət həm keyfiyyət, həm də kəmiyyət baxımından İranda ən yaxşı dekorativ məhsul brendidir və onun istehsal etdiyi məhsullar keyfiyyətçə dünyanın ən yaxşı istehsalçılarının (o cümlədən İtaliya və İspaniya istehsalçılarının) məhsulları ilə rəqabət aparmağa qadirdir."
        },
        {
            logo: majorca_logo,
            img: layer_ceramara,
            title: 'Majorca',
            about: ""
        }
    ]
    return(
        <div id="brend">
            <div className={styles.project_header}>
                <h1>Brendlər</h1>
            </div>
            <div className={`${styles.brands} container`}>
                <section>
                    <div className={styles.brands_blocks}>
                        {
                            brands_data.map((el, i) => {
                                return(
                                    <div key={i} className={styles.box} style={{
                                        backgroundImage: `url("${block_bg}")`,
                                        backgroundSize: "cover",
                                    }}>
                                        <div className={styles.box_before} style={{
                                            backgroundImage: `url(${el.img})`,
                                            backgroundSize: "cover",
                                        }}></div>
                                        <h2>{el.logo.length ? <img alt="" width="100%" src={el.logo}/> : el.title}</h2>
                                        <h3>{el.title}</h3>
                                        <p>
                                            {el.about}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Brands;