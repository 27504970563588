import React, { useEffect, useState } from "react";
import styles from './wp.module.css'
import wp_icon from '../assets/icons/logos_whatsapp-icon.png'
import { FloatButton } from "antd";

const WpContact = () => {
    const phoneNumber = "+994552555719"; // Замените на актуальный номер
    const message = ""; // Замените на ваше сообщение

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    // const whatsappLink = `https://web.whatsapp.com/send?phone=${phoneNumber}`;
    const [scrolledBelowThreshold, setScrolledBelowThreshold] = useState(false),
        [wpVisible, setWpVisible] = useState('none'),
        [scrolledNearBottom, setScrolledNearBottom] = useState(false);

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.scrollY;

        if (window.scrollY > 525) {
            setScrolledBelowThreshold(true);
        } else {
            setScrolledBelowThreshold(false);
        }

        if (documentHeight - scrollPosition - windowHeight <= 320) {
            setScrolledNearBottom(true);
        } else {
            setScrolledNearBottom(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    

    useEffect(() => {
        if (scrolledNearBottom) {
            setWpVisible('none')
        }else{
            setWpVisible('block')
        }
    }, [scrolledNearBottom]);

    useEffect(() => {
        if (scrolledBelowThreshold) {
            // Здесь можете вызвать нужную вам функцию
            setWpVisible('block')
        }else{
            setWpVisible('none')
        }
    }, [scrolledBelowThreshold]);


    return(
        <div className={styles.wp_ico}>
            <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <img src={wp_icon} className={styles.bell} alt="WhatsApp Icon"/>
            </a>
        </div>
    )
}

export default WpContact;