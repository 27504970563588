import axios from "axios"

export const getImagesAsync = (e) => {
    return async function(dispatch){
        try{
            let response = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${e.Ölkə}/${e.Çeşid}/${e.Ölçü}/${e.Nomenklatura}`)
            dispatch({
                type:'GET_IMAGES',
                payload: response.data || []
            })
        }catch(err){
            console.log(err)
            return
        }
    }
}

export const getProjectsİmages = (e) => {
    return async function(dispatch){
        let response = await axios.get(`https://canaqqalaseramik.az:5500/projects/folder/${e.image}`)
        dispatch({
            type:'PROJECTS_IMAGES',
            payload: response.data || []
        })
    }
}

export const getSliderImages = (e) => {
    return async function(dispatch){
        let response = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${e.Nomenklatura}`)
        dispatch({
            type:'SLIDER_IMAGES',
            payload: response.data || []
        })
    }
}

export const getProductsAsync = (page, country = '') => {
    return async function(dispatch, getState) {
        let response = await axios.get(`https://canaqqalaseramik.az:5500/products/items?page=${page}&limit=9`)
        dispatch({
            type: 'GET_PRODUCT_LIST',
            payload: response.data || []
        })
    }
}

export const getImageForTile = (tile) => {
    return async function(dispatch, getState){
        let res = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tile.Ölkə}/${tile.Çeşid}/${tile.Ölçü}/${tile.Nomenklatura}`)
        let img = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tile.Ölkə}/${tile.Çeşid}/${tile.Ölçü}/${tile.Nomenklatura}/${res.data.filter(e => !e.includes('.tif'))[0]}`, {
        responseType: 'arraybuffer'
      })
      const base64 = btoa(
        new Uint8Array(img.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      const imageUrl = `data:${img.headers['content-type']};base64,${base64}`;
        dispatch({
            type: 'GET_TILE_IMG',
            payload: imageUrl
        })
    }
}

export const getPortfolio = (obj) => {
    return{
        type: "GET_PORTFOLIO",
        payload: obj
    }
}

export const getCheckTypes = (type, target) => {
    return{
        type: "GET_TYPES",
        payload: type,
        target
    }
}

export const getFilteredArray = (arr) => {
    return{
        type: "GET_FILTERED",
        payload: arr
    }
}

export const getLazyLoading = (arr) => {
    return{
        type:"GET_LAZYLOADING",
        payload: arr
    }
}

export const lazyLoad = (arr, main_arr, index) => {
    return{
        type: "LAZY_LOAD",
        payload: arr,
        main_arr,
        index
    }
}