import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./admin.module.css"
import _Products from "./products/_products";
import _Gallery from "./products/_gallery";
import _Projects from "./products/_projects";
import _Slider from "./products/_slider";

const Admin = () => {    
    let [showProducts, setShowProducts] = useState(false)
    let [showGallery, setShowGallery] = useState(false)
    let [showProjects, setShowProjects] = useState(false)
    let [showSlider, setShowSlider] = useState(false)
    const [_connection, _setConnection] = useState(false),
    [_logData, _setLogData] = useState({}),
    [userType, setUserType] = useState(''),
    [passType, setPassType] = useState(''),
    [rPassType, setRPassType] = useState(''),
    [oldType, setOldType] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
         const getLog = async () => {
            try{
                const res = await axios.get('https://canaqqalaseramik.az:5500/login/user')
                _setLogData(res.data[0])
            }catch(err){
                console.log(err)
            }
        } 
        getLog()
    }, [])
    const _kaleLogin = () => {
        if(userType === _logData.user && passType === _logData.pass){
            _setConnection(true)
        }else{
            alert('Qeyd olunan məlumatlar səhfdi')
        }
        setUserType('')
        setPassType('')
    }

    // const _updateKaleLogin = async () => {
    //     if()
    // }


    if(_connection){
    return(
            <div className={styles.admin_side}>
                <div className={styles.admin_menu}>
                    {/* <div>
                        <form onSubmit={}>
                            <input placeholder="kohne paraol" type="password" onChange={(e) => {setOldType(e.target.value)}}/>
                            <input placeholder="teze parol" type="text" onChange={(e) => {setPassType(e.target.value)}}/>
                            <input placeholder="teze parol tekrar" type="text" onChange={(e) => {setRPassType(e.target.value)}}/>
                        <input value={'Parolu deyishtir'} type="submit"/>
                        </form>
                    </div> */}
                    <button className={showGallery ? styles.selected_menu : ''} onClick={() => {setShowGallery(!showGallery)}}>Gallereya</button>
                    <button className={showProducts ? styles.selected_menu : ''} onClick={() => {setShowProducts(!showProducts)}}>Produktlar</button>
                    <button className={showProjects ? styles.selected_menu : ''} onClick={() => {setShowProjects(!showProjects)}}>Proyektlər</button>
                    <button className={showSlider ? styles.selected_menu : ''} onClick={() => {setShowSlider(!showSlider)}}>Kolleksiyalar</button>
                </div>
                <div className={styles.collections_block}>
                    {
                        showGallery ? <_Gallery/> : ''
                    }
                    {
                        showProducts ? <_Products/> :''
                    }
                    {
                        showProjects ? <_Projects/> :''
                    }
                    {
                        showSlider ? <_Slider/> :''
                    }
                </div>
            </div>
        )
    }else{
        return(
            <div style={{width: '300px', margin: '0 auto', marginTop: '100px', display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <div>
                    <label htmlFor="login" style={{width: '100px', display: 'inline-block'}}>User adı</label>
                    <input type="text" id="login" onChange={(e) => {setUserType(e.target.value)}} value={userType}/>
                </div>
                <div>
                    <label htmlFor="pass" style={{width: '100px', display: 'inline-block'}}>Parol</label>
                    <input type="password" id="pass" onChange={(e) => {setPassType(e.target.value)}} value={passType}/>
                </div>
                <button style={{width: '80px', padding: '5px 0',margin: '10px auto'}} onClick={() => {_kaleLogin()}}>Login</button>
            </div>
        )
    }
}

            // {sliderImage == "" || sliderImage == null ? "" : <img width={100} height={100} src={sliderImage}/>} 
export default Admin;