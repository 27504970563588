const getTypesReducer = (state = [], action) => {
    switch(action.type){
        case "GET_TYPES":
            if(action.target != null){
                return action.payload.filter(item => item != action.target)
            }
            return [...state, ...action.payload]
        default: return [...state]
    }
}

export default getTypesReducer;