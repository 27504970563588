import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFlip, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import styles from "./history.module.css"
import styles_p from "../portfolio/protfolio.module.css"

import _1 from "../assets/portfolio/partners/1.png"
import _2 from "../assets/portfolio/partners/2.png"
import _3 from "../assets/portfolio/partners/3.png"
import _4 from "../assets/portfolio/partners/4.png"
import _5 from "../assets/portfolio/partners/5.png"
import _6 from "../assets/portfolio/partners/6.png"
import _7 from "../assets/portfolio/partners/7.png"
import _8 from "../assets/portfolio/partners/8.png"
import _9 from "../assets/portfolio/partners/9.png"
import _10 from "../assets/portfolio/partners/10.png"
import _11 from "../assets/portfolio/partners/11.png"
import _12 from "../assets/portfolio/partners/12.png"
import _13 from "../assets/portfolio/partners/13.png"
import _14 from "../assets/portfolio/partners/14.png"
import _15 from "../assets/portfolio/partners/15.png"
import _16 from "../assets/portfolio/partners/16.png"
import _17 from "../assets/portfolio/partners/17.png"
import _18 from "../assets/portfolio/partners/18.jpg"
import _19 from "../assets/portfolio/partners/19.jpg"
import _20 from "../assets/portfolio/partners/20.png"
import _21 from "../assets/portfolio/partners/21.png"
import _22 from "../assets/portfolio/partners/22.png"
import _23 from "../assets/portfolio/partners/23.png"
import _24 from "../assets/portfolio/partners/24.png"
import _25 from "../assets/portfolio/partners/25.png"
import _26 from "../assets/portfolio/partners/26.png"
import _27 from "../assets/portfolio/partners/27.png"
import head_bg from "../assets/history/green-head.jpg"
import roadmap_1995 from "../assets/history/roadmap/r_1995.jpg"
import roadmap_2000 from "../assets/history/roadmap/r_2000.png"
import roadmap_2010 from "../assets/history/roadmap/r_2010.png"
import roadmap_2023 from "../assets/history/roadmap/r_2023.png"
import { useDispatch } from "react-redux";

const History = () => {
    const roadmap = [
        {
            title: "1995",
            text: "Türkiyənin köklü Kalebodur şirkəti ilə əməkdaşlıq quraraq Azərbaycanda  Türkiyənin dünyaca bilinən markası olan Çanakkale Seramik brendinin satışını təşkil edən ilk mağazamızın açılışını etdik",
            img: roadmap_1995
        },
        {
            title: '2000',
            text: 'Başladıqdan qısa bir müddət sonra artan müştəri tələblərinə cavab verə bilmək üçün məhsul çeşidliyimizi davamlı böyüdərək  yerli və xarici 20-dən çox tanınmış markanın distributor kimi nümayəndəliyini əldə etmişik',
            img: roadmap_2000
        },
        {
            title: '2010',
            text: 'Müştərilərimiz tələbatını yerində və çevik qarşılamaq üçün 2 yeni satış sərgi salonunu  xidmətə açdıq. Münasib təkliflərimiz sayəsində topdan və pərakəndə satış üzrə bazarın liderinə çevrildik',
            img: roadmap_2010
        },
        {
            title: '2023',
            text: 'Hazırda 30 ildir davam edən daimi fəaliyyətimiz sayəsində ölkə üzrə tələbatı olan hər bir hüquqi və fiziki şəxsin etibarlı tərəfdaşı olaraq davam etməyin qürurunu yaşayırıq',
            img: roadmap_2023
        }
    ]
    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({type: "STOP_INTERVAL"})
    }, [])
    const partners = [
        {
            img: _1
        },
        {
            img: _2
        },
        {
            img: _3
        },
        {
            img: _4
        },
        {
            img: _5
        },
        {
            img: _6
        },
        {
            img: _7
        },
        {
            img: _8
        },
        {
            img: _9
        },
        {
            img: _10
        },
        {
            img: _11
        },
        {
            img: _12
        },
        {
            img: _14
        },
        {
            img: _15
        },
        {
            img: _16
        },
        {
            img: _17
        },
        {
            img: _18
        },
        {
            img: _19
        },
        {
            img: _20
        },
        {
            img: _21
        },
        {
            img: _22
        },
        {
            img: _23
        },
        {
            img: _24
        },
        {
            img: _25
        },
        {
            img: _26
        },
        {
            img: _27
        },
    ]
    let converted_partners = []
    for(let i = 0; i < partners.length; i++){
        let obj = []
        obj.push(partners[i], partners[i])
        converted_partners.push(obj)
    }
    return (
        <div>
            <div className={styles.history_header} style={{backgroundImage: `url("${head_bg}")`, backgroundSize: 'contain'}}>
                <h1>Tarixçə</h1>
            </div>
            <div className={`${styles.history_body} container`}>
                <div className={styles.history_title}>
                    <div className={styles.title_text} style={{
                        lineHeight: '27px'
                    }}>
                        
                            <p>
                            <span style={{fontWeight: "400", fontSize: "2em", paddingRight: "18px"}}>Çanaqqala Seramik</span> olaraq 1994-cü ildə Azərbaycanın ilk və ən böyük seramik satış mərkəzi kimi fəaliyyətə başladığımız gündən hədəfimiz bol çeşid və keyfiyyətli məhsullar təqdim edərək bu sahədə mümkün olan bütün yenilikləri istehlak bazarına gətirmək olmuşdur. Avropa və Asiyanın aparıcı istehsalçıları ilə mütəmadi əməkdaşlığımız şirkətimizi etibarlı tərəfdaş kimi tanıtmaqla yanaşı, bol çeşid imkanlarını ən uyğun qiymətlərlə təqdim etməyə və beləliklə müştərilərimizin rəğbətini qazanmağımıza zəmin yaratmışdır.
                            </p>
                            <p>
                            30 ilə yaxındır davam edən fəaliyyətimiz nəticəsində Türkiyə, İspaniya, İtaliya, Almaniya, Çin, Ukrayna, İran, Rusiya, Özbəkistan kimi ölkələrin seramik sahəsində qlobal olaraq tanınan brendlərinin Azərbaycan üzrə nümayəndəliyini davam etdirərək müştərilərimizə xidmət etməyə davam edirik.
                            </p> 
                            <p>
                            Ən böyük özəlliyimiz daim yenilənən məhsul çeşidliyi və stok davamlılığı ilə yanaşı müştəri məmnuniyyətini əsas alaraq onlara daxili və xarici məkanlarının həllində peşəkar komandamız tərəfindən həllər təklif etməkdir.
                            </p>
                        
                    </div>
                </div>
                <div className={styles.history_progression}>
                    {
                    roadmap.map((e, i) => {
                        return (
                                <div key={i} className={styles.progression_inner} style={{padding: '60px 0'}}>
                                    <h3>
                                        <h2>{e.title}</h2>
                                    </h3>
                                    <div className={styles.inner_item} style={{
                                        flexDirection: i%2 ? 'row-reverse' : 'row'
                                    }}>
                                        <div>
                                            <p style={{margin: '0 auto'}}>{e.text}</p>
                                        </div>
                                        <div>
                                            <img style={{margin: '0 auto'}} src={e.img} alt={e.title} />
                                        </div>
                                    </div>
                                </div>
                        )
                        
                    })}
                </div>
                <div className={styles.partners}>
                    <h3 style={{textAlign: 'center', fontSize: '1.7em'}}>Bizim Partnyorlar</h3>
                    <div className={styles_p.partners_blocks}>
                        {
                            converted_partners.map((el, i) => {
                                return(
                                    <Swiper
                                        key={i}
                                        effect={"flip"}
                                        allowTouchMove={false}
                                        grabCursor={false}
                                        autoplay={{
                                        delay: Math.floor(Math.random() * 7000) + 3000,
                                        disableOnInteraction: false,
                                        }}
                                        loop={false}
                                        pagination={false}
                                        navigation={false}
                                        modules={[Autoplay, EffectFlip, Pagination, Navigation]}
                                        className={styles_p.mySwiper}
                                    >
                                        {
                                            el.map((el2, i2) => {               
                                                return(
                                                    <SwiperSlide key={i2}>
                                                        <div style={{backgroundImage: `url(${el2.img})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', height: '100%'}}></div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History;