import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../admin.module.css"
import { Button, Image } from "antd";

const _Products = () => {
    let [productsCollection, setProductCollection] = useState([])
    let [productTitle, setProductTitle] = useState('')
    let [productType, setProductType] = useState('')
    let [productSize, setProductSize] = useState('')
    let [productCountry, setProductCountry] = useState('')
    let [productTexture, setProductTexture] = useState('')
    let [productCover, setProductCover] = useState('')
    let [stock, setStock] = useState(true)
    let [isUpdating, setIsUpdating] = useState('')
    let [updateItemText, setUpdateItemText] = useState('')
    let [searchInput, setSearchInput] = useState('')
    let [foundProducts, setFoundProducts] = useState([])
    let [selectedImages, setSelectedImages] = useState(0)
    let [addImageLoading, setAddImageLoading] = useState(false)
    let [mainImageLoading, setMainImageLoading] = useState(false)
    let [updateLoading, setUdpateLoading] = useState(false)
    let [addLoading, setAddLoading] = useState(false)
    let [selectedUpdateImages, setSelectedUpdateImages] = useState(0)
    let [forceUpdate, setForceUpdate] = useState(1)
    let [zovqeGore, setZovqeGore] = useState('')

    
    async function getCollectionForProducts(){
        try{
            const res = await axios.get(`https://canaqqalaseramik.az:5500/products/all`)
            setProductCollection(res.data.sort((a, b) => b.views - a.views))
        }catch(err){
            console.log(err)
        }
    }
    async function deleteProduct(id, country, type, size, nomenklatura){
        try{
            const response = await axios.delete(`https://canaqqalaseramik.az:5500/product/delete/${country}/${type}/${size}/${nomenklatura}/${id}`);
            const newProductCollection = productsCollection.filter(i => i._id !== id)
            setProductCollection(newProductCollection)
            setSearchInput(searchInput)
        }catch(err){
            console.log(err)
        }
    }
    async function addProduct(e){
        setAddLoading(true)
        e.preventDefault();
        try{

            const formData = new FormData()
            formData.append('nomenklatura', productTitle)
            formData.append('type', productType)
            formData.append('size', productSize)
            formData.append('country', productCountry)
            formData.append('texture', productTexture)
            formData.append('cover', productCover)
            formData.append('views', 0)
            formData.append('stock', true)
            formData.append('zovqe', zovqeGore)
            const fileInput = document.querySelector('input[type="file"]');
            for (let i = 0; i < fileInput.files.length; i++) {
                formData.append('product_add_image', fileInput.files[i]);
              }

            const res = await axios.post('https://canaqqalaseramik.az:5500/product/add', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setProductCollection(prev => [...prev, res.data])
            setProductTitle('')
            setProductType('')
            setProductSize('')
            setProductCountry('')
            setProductTexture('')
            setProductCover('')
            setAddLoading(false)
            setZovqeGore('')
        }catch(err){
            console.log(err)
        }
    }
    const addProductImage = async (e, obj) => {
        setAddImageLoading(true)
        e.preventDefault()
        try{
            const formData = new FormData()
            const fileInput = document.querySelector('input[name="add_image"]');
            formData.append('nomenklatura', obj.Nomenklatura)
            formData.append('country', obj.Ölkə)
            formData.append('size', obj.Ölçü)
            formData.append('type', obj.Çeşid)
            formData.append('stock', obj.stock)
            for (let i = 0; i < fileInput.files.length; i++) {
                formData.append('add_image', fileInput.files[i]);
            }
            const res = await axios.post('https://canaqqalaseramik.az:5500/add_image_product', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setAddImageLoading(false)
        }catch(err){
            console.log(err)
        }
    }
    const updateItem = async (e) => {
        setUdpateLoading(true)
        e.preventDefault()
        try{
            const res = await axios.put(`https://canaqqalaseramik.az:5500/product/update/${isUpdating}`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                Tekstura: productTexture.length ? productTexture : '',
                Üzləmə: productCover.length ? productCover : '',
                "Zovqe gore": zovqeGore.length ? zovqeGore : '',
                stock: stock.length ? stock : true,
            })
            const updatedItemIndex = productsCollection.findIndex(i => i._id === isUpdating)
            productsCollection[updatedItemIndex].title = updateItemText
            // setUpdateItemText('')
            // setProductType('')
            // setProductSize('')
            // setProductCountry('')
            setProductTexture('')
            setProductCover('')
            setZovqeGore('')
            setUdpateLoading(false)
        }catch(err){
            console.log(err)
        }
    }
    const updateImage = async (e, obj) => {
        setMainImageLoading(true)
        e.preventDefault()
        try{
            const formData = new FormData()
            const fileInput = document.querySelector('input[name="update_product_image"]');
            formData.append('nomenklatura', obj.Nomenklatura)
            formData.append('country', obj.Ölkə)
            formData.append('size', obj.Ölçü)
            formData.append('type', obj.Çeşid)
            formData.append('stock', obj.stock)
            for (let i = 0; i < fileInput.files.length; i++) {
                formData.append('update_product_image', fileInput.files[i]);
            }
            const res = await axios.post('https://canaqqalaseramik.az:5500/update_img', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setMainImageLoading(false)
        }catch(err){
            console.log(err)
        }
    }
    
    function handleUpdateImageChange() {
        const imageInput = document.getElementById('image5');
        const selectedUpdateImages = imageInput.files;
        
        if (selectedUpdateImages) {
            setSelectedUpdateImages(selectedUpdateImages.length)
        } else {
            setSelectedUpdateImages([].length)
        }
    }

    function handleImageChange() {
        const imageInput = document.getElementById('image4');
        const selectedImages = imageInput.files;
        
        if (selectedImages) {
            setSelectedImages(selectedImages.length)
        } else {
            setSelectedImages([].length)
        }
    }

    const renderUpdateForm = (obj) => {
        return(
            <div className={styles.update_form}>
                <form onSubmit={e => {updateItem(e)}}>
                    <h4>Tezelemek</h4>
                    <div className={styles.update_selects}>
                        <label for="tekstura" >Tekstura </label>
                        <select required id="tekstura" onChange={(e) => {setProductTexture(e.target.value)}}>
                            <option value={obj.Tekstura}>{obj.Tekstura}</option>
                            <option value="Mərmər">Mərmər</option>
                            <option value="Dekorativ">Dekorativ</option>
                            <option value="Kərpic">Kərpic</option>
                            <option value="Monocolor">Monocolor</option>
                            <option value="Beton">Beton</option>
                            <option value="Taxta">Taxta</option>
                            <option value="Terrazzo">Terrazzo</option>
                            <option value="Daş">Daş</option>
                        </select>
                        <label htmlFor="add_zovqe">Zovqe gore</label>
                        <select id="add_zovqe" onChange={(e) => {setZovqeGore(e.target.value)}}>
                            <option value={obj["Zovqe gore"]}>{obj["Zovqe gore"]}</option>
                            <option value=" ">Silmek</option>
                            <option value="Çöl Məkan">Çöl Məkan</option>
                            <option value="İç Məkan">İç Məkan</option>
                            <option value="Ən çox satılan">Ən çox satılan</option>
                            <option value="YENI KOLLEKSIYA">Yeni Kolleksİya</option>
                            <option value="KALESINTERFLEX">Kalesinterflex</option>
                            <option value="fasad">Fasad</option>
                            <option value="taxta">Taxta</option>
                        </select>
                        <label for="üzleme">Üzleme </label>
                        <select required id="üzleme" onChange={(e) => {setProductCover(e.target.value)}}>
                            <option value={obj.Üzləmə}>{obj.Üzləmə}</option>
                            <option value="Parlaq">Parlaq</option>
                            <option value="Mat">Mat</option>
                            <option value="Sugar Effect">Sugar Effect</option>
                        </select>
                        <label for="stock">Stok</label>
                        <select required id="stock" onChange={(e) => {setStock(e.target.value)}}>
                            <option value={obj.stock}>{obj.stock ? "Var" : 'Yoxdu'}</option>
                            <option value={true}>Var</option>
                            <option value={false}>Yoxdu</option>
                        </select>
                    </div>
                        {updateLoading ? 
                        <Button className={styles.update} type="primary" loading style={{padding: 'inherit'}}>
                            Qözləyin
                        </Button> :
                        <Button className={styles.update} type="default" htmlType="submit"  style={{padding: 'inherit'}}>
                            Tezelemek
                        </Button>
                        }
                </form>
                <div>
                    <h3>Produkt şəkilləri</h3>
                    <TileImage product = {obj} update={forceUpdate}/>
                </div>
                <form onSubmit={(e) => {addProductImage(e, obj)}} className={styles.addImage}>
                    <h3>Şəkil əlave et</h3>
                    <input type="file" name="add_image" multiple required/>
                    
                    {addImageLoading ? 
                        <Button className={styles.update} type="primary" loading>
                            Qözləyin
                        </Button> :
                        <Button className={styles.update} type="default" htmlType="submit">
                            Yüklə
                        </Button>
                        }
                </form>
                <form onSubmit={(e) => {updateImage(e, obj)}}>
                    <div className={styles.add_btns}>
                        <h3>Əsas şəkili seç</h3>
                        <p>{obj.Nomenklatura}</p>
                        <div className={styles.load_file}>                        
                            <label htmlFor="image5">Şəkil seç</label> <span>Seçilən şəkil sayı: {selectedUpdateImages}</span>
                            <input type="file" name="update_product_image" id="image5" style={{visibility: "hidden"}}  required onChange={() => {handleUpdateImageChange()}}/>
                        </div>
                    </div>
                    {mainImageLoading ? 
                        <Button className={styles.update} type="primary" loading style={{padding: 'inherit'}}>
                            Qözləyin
                        </Button> :
                        <Button className={styles.update} type="default" htmlType="submit"  style={{padding: 'inherit'}}>
                            Əsas şəkili təzələ
                        </Button>
                        }
                </form>
                <button onClick={() => {setIsUpdating('')}}>Close</button>
            </div>
        )
    }

    useEffect(() => {
        async function findProducts(){
            const res = await axios.get('https://canaqqalaseramik.az:5500/products/all')
            const filtered = res.data.filter((item) => {
                return item.Nomenklatura.toLowerCase().includes(searchInput.toLowerCase())
            })
            if(searchInput.length){
                setFoundProducts(filtered.slice(0, 4))
            }else{
                setFoundProducts([])
            }
        }
        findProducts()
    }, [searchInput])

    useEffect(() => {
        getCollectionForProducts()
    }, [])
    return(
        <div className={styles.selected_option}>
            <div className={styles.add_form}>
                <form className={styles.form} onSubmit={(e) => {addProduct(e)}} encType="multipart/form-data">
                <h4>Teze produkt elave etmek</h4>
                    <div>
                        <input type="text" placeholder="Produkt nomenklaturası" onChange={(e) => {setProductTitle(e.target.value)}} required value={productTitle}/>
                        <label htmlFor="add_type">Çeşid</label>
                        <select id="add_type" onChange={(e) => setProductType(e.target.value)} required>
                            <option value=""></option>
                            <option value="QRANİT">QRANİT</option>
                            <option value="DEKOR">DEKOR</option>
                            <option value="KAFEL">KAFEL</option>
                            <option value="METLAX">METLAX</option>
                            <option value="FLEX">FLEX</option>
                        </select>
                        <label htmlFor="add_size">Ölçü</label>
                        <select id="add_size" onChange={(e) => {setProductSize(e.target.value)}}>
                            <option value=""></option>
                            <option value="7.5X30">7.5x30</option>
                            <option value="10X20">10x20</option>
                            <option value="10X30">10x30</option>
                            <option value="15X60">15x60</option>
                            <option value="15X90">15x90</option>
                            <option value="19.7x19.7">19.7x19.7</option>
                            <option value="20X20">20x20</option>
                            <option value="20X50">20x50</option>
                            <option value="20X120">20x120</option>
                            <option value="25X40">25x40</option>
                            <option value="25X50">25x50</option>
                            <option value="25X60">25x60</option>
                            <option value="25X75">25x75</option>
                            <option value="30X30">30x30</option>
                            <option value="30X40">30x40</option>
                            <option value="30X50">30x50</option>
                            <option value="30X60">30x60</option>
                            <option value="30X90">30x90</option>
                            <option value="40X40">40x40</option>
                            <option value="41X120">41x120</option>
                            <option value="45X45">45x45</option>
                            <option value="60X60">60x60</option>
                            <option value="60X120">60x120</option>
                            <option value="100X300">100x300</option>
                            <option value="120X180">120x180</option>
                        </select>
                        <label htmlFor="add_country">Ölkə</label>
                        <select id="add_country" onChange={(e) => {setProductCountry(e.target.value)}}>
                            <option value=""></option>
                            <option value="Azerbaycan">AZƏRBAYCAN</option>
                            <option value="TÜRKİYƏ">TÜRKİYƏ</option>
                            <option value="ÇİN">ÇİN</option>
                            <option value="ÖZBƏKİSTAN">ÖZBƏKİSTAN</option>
                            <option value="RUSİYA">RUSİYA</option>
                            <option value="İRAN">İRAN</option>
                        </select>
                        <label htmlFor="add_zovqe">Zovqe gore</label>
                        <select id="add_zovqe" onChange={(e) => {setZovqeGore(e.target.value)}}>
                            <option value=""></option>
                            <option value="Çöl Məkan">Çöl Məkan</option>
                            <option value="İç Məkan">İç Məkan</option>
                            <option value="Ən çox satılan">Ən çox satılan</option>
                            <option value="YENI KOLLEKSIYA">Yeni Kolleksİya</option>
                            <option value="KALESINTERFLEX">Kalesinterflex</option>
                            <option value="fasad">Fasad</option>
                            <option value="taxta">Taxta</option>
                        </select>
                        <label htmlFor="add_texture">Tekstura</label>
                        <select id="add_texture" onChange={(e) => {setProductTexture(e.target.value)}}>
                            <option value=""></option>
                            <option value="Mərmər">Mərmər</option>
                            <option value="Dekorativ">Dekorativ</option>
                            <option value="Kərpic">Kərpic</option>
                            <option value="Monocolor">Monocolor</option>
                            <option value="Beton">Beton</option>
                            <option value="Taxta">Taxta</option>
                            <option value="Terrazzo">Terrazzo</option>
                            <option value="Daş">Daş</option>
                        </select>
                        <label htmlFor="add_cover">Üzləmə</label>
                        <select id="add_cover" onChange={(e) => {setProductCover(e.target.value)}}>
                            <option value=""></option>
                            <option value="Parlaq">Parlaq</option>
                            <option value="Mat">Mat</option>
                            <option value="Sugar Effect">Sugar Effect</option>
                        </select>
                    </div>
                    <div className={styles.add_side}>
                        <div></div>
                        <div className={styles.add_btns}>
                            <div className={styles.load_file}>
                                <label htmlFor="image4">Şəkil seç</label><span>Seçilən şəkil sayı: {selectedImages}</span>
                                <input type="file" name="product_add_image" id="image4" style={{visibility: "hidden"}} multiple required onChange={(e) => {handleImageChange()}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.addBtns}>
                        {addLoading ? 
                            <Button className={styles.add} type="primary" loading style={{padding: 'inherit', backgroundColor: '#4b2b2b'}}>
                                Qözləyin
                            </Button> :
                            <Button className={styles.add} type="default" htmlType="submit"  style={{padding: 'inherit', backgroundColor: 'inherit'}}>
                                Elave et
                            </Button>
                            }
                    </div>
                </form>
            </div>
            <h1>Produkt list</h1>
            <div>
                <input type="text" style={{width: "100%", padding: '10px 5px'}} placeholder="Nomenklaturanı axtar" onChange={(e) => {setSearchInput(e.target.value)}} value={searchInput}/>
                {
                    foundProducts.map((e, i) => {
                        return <div style={{backgroundColor: "#f3f3f3", border: '1px solid #000'}} key={i} className={styles.items}>
                        <div className={styles.item}>
                                <div>
                                    <h3>
                                        {i + 1 + ' '} 
                                        {e.Nomenklatura}
                                        <div>Baxış sayı: {e.views}</div>
                                    </h3>
                                </div>
                                <div className={styles.item_buttons}>
                                    <button className={styles.update} onClick={() => {
                                        setProductTexture(e.Tekstura)
                                        setProductCover(e.Üzləmə)
                                        setStock(e.stock)
                                        setIsUpdating(e._id)}}>Tezelemek</button>
                                    <button className={styles.delete} onClick={() => {deleteProduct(e._id, e.Ölkə, e.Çeşid, e.Ölçü, e.Nomenklatura)}}>Silmek</button>
                                </div>
                            </div>
                                {
                                    isUpdating === e._id ? renderUpdateForm(e) : <></>
                                }
                    </div>
                    })
                }
            </div>
            {
                productsCollection.map((e, i)=> {
                    
                    return <div key={i} className={styles.items}>
                        <div className={styles.item}>
                                <div>
                                    <h3>
                                        {i + 1 + ' '} 
                                        {e.Nomenklatura}
                                        <div>Baxış sayı: {e.views}</div>
                                    </h3>
                                </div>
                                <div className={styles.item_buttons}>
                                    <button className={styles.update} onClick={() => {
                                        setProductTexture(e.Tekstura)
                                        setProductCover(e.Üzləmə)
                                        setStock(e.stock)
                                        setIsUpdating(e._id)}}>Tezelemek</button>
                                    <button className={styles.delete} onClick={() => {deleteProduct(e._id, e.Ölkə, e.Çeşid, e.Ölçü, e.Nomenklatura)}}>Silmek</button>
                                </div>
                            </div>
                                {
                                    isUpdating === e._id ? renderUpdateForm(e) : <></>
                                }
                    </div>
                })
            }
        </div>
    )
}

const TileImage = (props) => {
    const [images, setImages] = useState([]),
    [imgLoading, setImgLoading] = useState(true)

    const handleDeleteImage = async (country, type, size, product, imageName) => {
        try {
          const response = await axios.delete(`https://canaqqalaseramik.az:5500/product/deleteImage/${country}/${type}/${size}/${product}/${imageName}`);
          console.log(response.data.message);
          let images = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${props.product.Ölkə}/${props.product.Çeşid}/${props.product.Ölçü}/${props.product.Nomenklatura}`)
          setImages(images.data)
          // Здесь вы можете обновить список изображений после удаления
          // Например, вызвать функцию для загрузки нового списка
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(() => {
        async function getImages(){ 
            try{
                let images = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${props.product.Ölkə}/${props.product.Çeşid}/${props.product.Ölçü}/${props.product.Nomenklatura}`)
                setImages(images.data)
            }catch(err){
                console.log(err)
            }finally{
                setImgLoading(false)
            }
        }

        getImages()
    }, [props.update])

    return(
        <div className={styles.images_gallery}>
            <Image.PreviewGroup
                preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
            {
                images.map((image, index) => {
                    return(
                        <div key={index} className={styles.image_tile} style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            margin: "10px",
                            display: "inline-block"
                        }}>
                            <Image
                                preview={{
                                 mask: 'Baxmaq'   
                                }}
                                height={'100px'}
                                src={`https://canaqqalaseramik.az:5500/product/image/${props.product.Ölkə}/${props.product.Çeşid}/${props.product.Ölçü}/${props.product.Nomenklatura}/${image}`}
                            />
                            <button style={{marginTop: '10px', cursor: 'pointer', backgroundColor: '#F14907', color: "#fff", fontSize: '14px', padding: '3px 5px'}} onClick={() => {handleDeleteImage(props.product.Ölkə, props.product.Çeşid, props.product.Ölçü, props.product.Nomenklatura, image)}}>Şəkili sil</button>
                        </div>
                    )
                })
            }
            </Image.PreviewGroup>
        </div>
        )
}

export default _Products;