import React, { useEffect, useState } from "react";

import styles from "./protfolio.module.css"
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getProjectsİmages } from "../actions";
import Loading from "../loading/loading";

const Projects = () => {
    const [projectsList, setProjectsList] = useState([])
    const projectsImages = useSelector((state) => state.projectImages)
    const [isLoading, setIsLoading] = useState(true)
    let dispatch = useDispatch()
    async function getProjectsList(){
        try{
            const res = await axios.get('https://canaqqalaseramik.az:5500/projects/all_items')
            setProjectsList(res.data)
            res.data.forEach(e => {
                dispatch(getProjectsİmages(e))
                setTimeout(() => {setIsLoading(false)}, 500)
            })
        }catch(err){
            console.log(err)
        }
    }  
    useEffect(() => { 
        getProjectsList()
    }, [])
    if(isLoading){
        return <div id="proyektler">
            <div className={styles.project_header}>
                <h1>PROYEKTLƏR</h1>
            </div>
            <Loading/>
        </div>
    }
    return(
        <div id="proyektler" className={styles.projects}>
            
            <div className={styles.project_header}>
                <h1>PROYEKTLƏR</h1>
            </div>
            <div className={`${styles.projects_list} container`}>
                {
                    projectsList.map((el, i) => {
                        return(
                            <a key={i} target="_blank" href={`/projects/${el._id}`}>
                                <ProjectItem object={el}/>
                            </a>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Projects;

const ProjectItem = ({object}) => {
    let [image, setImage] = useState('')
    let [loading, setLoading] = useState(true)
    async function getImage(){
        try{
            let res = await axios.get(`https://canaqqalaseramik.az:5500/projects/folder/${object.image}`)
            setImage(res.data[res.data.length - 1])
        }catch(err){
            console.log(err)
        }finally{
            setTimeout(() => {setLoading(false)}, 1000)
        }
    }
    useEffect(() => {
        getImage()
    }, [])
    useEffect(() => {
        setLoading(true)
        getImage()
    }, [object])
    if(loading) return <Loading/>
    return (
        <div className={styles.project_item}>
            <img alt=""  style={{backgroundImage: `url("https://canaqqalaseramik.az:5500/projects/image/${object.image}/${image}")`, backgroundSize: 'cover', backgroundPosition: 'center'}}/>
            <div className={styles.item_title}>
                <h3>{object.title}</h3>
                <p>{object.city}</p>
            </div>
        </div>
    )
}