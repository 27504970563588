import React from "react";

import styles from './home.module.css';
import sekil1 from '../assets/home/catalog/Sekil1.jpg'
import sekil2 from '../assets/home/catalog/Sekil2.jpg'
import sekil3 from '../assets/home/catalog/Sekil3.jpg'
import sekil4 from '../assets/home/catalog/Sekil4.jpg'
import sekil5 from '../assets/home/catalog/Sekil5.jpg'
import sekil6 from '../assets/home/catalog/Sekil6.jpg'
import sekil7 from '../assets/home/catalog/Sekil7.jpg'
import { Link } from "react-router-dom/cjs/react-router-dom";

const Catalogue = () => {
    const catalog_data = [
        {
            name: 'Taxta',
            img: sekil1,
            link: 'taxta'
        },
        {
            name: 'Fasad',
            img: sekil2,
            link: 'fasad'
        },
        {
            name: 'Kalesİnterflex',
            img: sekil3,
            link: 'KALESINTERFLEX'
        },
        {
            name: 'Yenİ Kolleksİya',
            img: sekil4,
            link: 'YENI KOLLEKSIYA'
        },
        {
            name: 'Ən çox satılan',
            img: sekil5,
            link: 'Ən çox satılan'
        },
        {
            name: 'İç Məkan',
            img: sekil6,
            link: 'İç Məkan'
        },
        {
            name: 'Çöl Məkan',
            img: sekil7,
            link: 'Çöl Məkan'
        }
    ]
    return(
        <section className={styles.catalogue_section}>
            <div className={styles.catalog_grid}>
                {
                    catalog_data.map((element, id) => {
                        return(
                                <div className={`${styles[`item${id}`]}  ${styles['item']}`} style={{ backgroundImage: `url("${element.img}") `, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} key={id}>
                                    <Link to={`/kataloq/${element.link}`}>
                                        <h3>{element.name}</h3>
                                    </Link>
                                </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Catalogue;