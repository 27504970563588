import React from "react";

import styles from './footer.module.css'

import logo from '../assets/logo.png'
import insta_ico from '../assets/icons/instagram_black.svg'
import facebook_ico from '../assets/icons/facebook.svg'
import { Link } from "react-router-dom/cjs/react-router-dom";


const Footer = ({footer}) => {
    const questions = ['Hansı növ məhsulları təklif edirsiniz?', 'Məhsullarınız hara istehsalıdır?', 'Sərgi salon/mağazalarınız harada yerləşir?']
    return(
        <footer ref={footer} id="footer" className={styles.footer}>
            <div className={styles.footer_img}>
                <img alt="logo ico" src={logo}/>
            </div>
            <ul className={styles.footer_info_list}>
                <li className={styles.collapsible}>
                    <input type="checkbox" id="collapsible-info"/>
                    <label htmlFor="collapsible-info">Haqqımızda</label>
                    <ul className={styles.collapsible_info}>
                        <li><Link to="/tarix">Tarixçə</Link></li>
                        <li><Link to="/katalog">Məhsullar</Link></li>
                    </ul>
                </li>
                <li className={styles.collapsible}>
                    <input type="checkbox" id="collapsible-product"/>
                    <label htmlFor="collapsible-product">Sual & Cavab</label>
                    <ul className={styles.collapsible_info}>
                        <li><Link to="/ck-showroom/ck">Hardan almaq olar?</Link></li>
                        <li><Link to="/faq">{questions[Math.floor(Math.random() * 3)]}</Link></li>
                        <li><Link to="/faq">Daha çox.....</Link></li>
                    </ul>
                </li>
                <li className={styles.collapsible}>
                    <input type="checkbox" id="collapsible-production"/>
                    <label htmlFor="collapsible-production">Portfolio</label>
                    <ul className={styles.collapsible_info}>
                        <li><Link to="/portfolio">Brendlər</Link></li>
                        <li><Link to="/portfolio">Proyektlər</Link></li>
                        <li>Keyfiyyət Sertifikatları</li>
                    </ul>
                </li>
                <li className={styles.collapsible}>
                    <input type="checkbox" id="collapsible-contacts"/>
                    <label htmlFor="collapsible-contacts">Əlaqə</label>
                    <ul className={styles.collapsible_info}>
                        <li><b>Ünvan: </b></li>
                        <li><a href="https://goo.gl/maps/gdYDXbPCX5BDYJwb6" target="_blank">Xətai pr. 43, Bakı, Azərbaycan, AZ1008</a></li>
                        <li><a href="https://goo.gl/maps/z3kfZSp6frac2s6z6" target="_blank">Xətai pr. 158, Bakı, Azərbaycan, AZ1008</a></li>
                        <li><b>Telefon:</b> </li>
                        <li><a href="tel:+994552555719">(+994 55) 255 57 19</a></li>
                        <li><b>Email: </b></li>
                        <li><a href="mailto: office@canaqqalaseramik.az">office@canaqqalaseramik.az</a></li>
                        <li className={styles.social_medias}>
                            <a href="https://www.facebook.com/canaqqala.seramik.az?mibextid=LQQJ4d" target="_blank"><img alt="facebook ico" src={facebook_ico}/></a>
                            <a href="https://instagram.com/canaqqalaseramik?igshid=MzRlODBiNWFlZA==" target="_blank"><img alt="instagram ico" src={insta_ico}/></a>
                        </li>
                    </ul>
                </li>
            </ul>
            <p className={styles.footer_copyright}>©Copyright 2023</p>
        </footer>
    )
}

export default Footer;