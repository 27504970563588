import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./catalog.module.css";
import Loading from "../loading/loading";

const TileCard = ({tileObject}) => {
    const [imgLink, setImgLink] = useState("")
    const [imgLoading, setImgLoading] = useState(true)
    const [noImg, setNoImg] = useState(true)

    useEffect(() => {
        async function getImg(){
            try {
                let response = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}`)
                let main = response.data.filter(e => e.includes('main'))
                let design = response.data.filter(e => e.includes('Design'))
                if(main.length){
                    setImgLink(main[0])
                }else if(!main.length && design.length){
                    setImgLink(design[0])
                }else setImgLink(response.data.filter(e => !e.includes('.tif'))[0])
            } catch (e) {
                setImgLink("NoImg")
            } finally {
                setTimeout(() => {setImgLoading(false)}, 500)
                setNoImg(false)

            }
        }
        getImg()
      }, [tileObject]);
    return(
        <div className={styles.item_gradient} style={{maxWidth: "340px", margin: '0 auto'}}>
        <div className={styles.item}>
                {
                    tileObject._id.length ? (<a target="_blank" href={`/product/${tileObject._id}`}>
                    {imgLoading ? <div className={styles.item_background}><Loading/></div> : (imgLink !== "NoImg" ? <div className={styles.item_background}
                        style={{maxWidth: "340px"}}
                        >
                        <img alt={tileObject.Nomenklatura} height="100%" width="auto" src={`https://canaqqalaseramik.az:5500/product/image/${tileObject.Ölkə}/${tileObject.Çeşid}/${tileObject.Ölçü}/${tileObject.Nomenklatura}/${imgLink}`}/>
                        <div className={styles.gradient_anim}></div>
                        {tileObject.stock ? <div style={{position: 'absolute', backgroundColor: '#4B6258', borderBottomLeftRadius: '10px',color: 'white', padding: '5px 0', width: '80px', right: 0, top: 0, textAlign: 'center'}}>STOKDA VAR</div> : <div style={{position: 'absolute', backgroundColor: '#f36258', borderBottomLeftRadius: '10px',color: 'white', padding: '5px 10px', width: '80px', right: 0, top: 0, textAlign: 'center'}}>STOKDA YOXDUR</div> }
                    </div> : <div>NO IMG</div>)}
                    <div className={styles.item_titles}>
                        <p>{tileObject.Ölkə}</p>
                        <p>{tileObject.Nomenklatura}</p>
                    </div>
                </a>) : <></>
                }
            </div>    
        </div>
    )
}

export default TileCard;