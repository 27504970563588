import React, { useEffect, useState } from "react";
import styles from "./protfolio.module.css"
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";

import head_bg from "../assets/history/green-head.jpg"
import Loading from "../loading/loading";

const Project = () => {
    const {id} = useParams(),
    [project, setProject] = useState({}),
    [projectImages, setProjectImages] = useState([]),
    [loading, setLoading] = useState(false)

    useEffect(() => {
        async function getProject(){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/projects/project/${id}`)
                
                const images = await axios.get(`https://canaqqalaseramik.az:5500/projects/folder/${res.data.image}`)
                setProject(res.data)
                setProjectImages(images.data)
                setLoading(true)
            }catch(err){
                console.log(err)
            }
        }
        getProject()
    }, [])
    if(!loading){
        return <div><Loading/></div>
    }
    return(
        <div className={styles.exact_project}>
            <div className={styles.project_title} style={{
                backgroundImage: `url("${head_bg}")`,
                backgroundSize: 'contain',
                fontSize: "2em",
                color: "#fff"
                }}>{project.title}</div>
            <div className={styles.projects_gallery_side}>
                <h2 style={{textAlign: 'center'}}>{project.city}</h2>
                <div className={styles.project_gallery}>
                    {
                        projectImages.map((e, i) => {
                            return(
                                <div key={i} style={{
                                    backgroundImage: `url("https://canaqqalaseramik.az:5500/projects/image/${project.image}/${e}")`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundColor: 'inherit'
                                }}></div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Project;