import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../admin.module.css"

const _Projects = () => {
    let [projectsCollection, setProjectsCollection] = useState([])
    let [projectTitle, setProjectTitle] = useState('')
    let [projectCity, setProjectCity] = useState('')
    let [isUpdating, setIsUpdating] = useState('')
    let [updateItemText, setUpdateItemText] = useState('')
    
        async function deleteProjectsItem(image, id){
            try{
                const response = await axios.delete(`https://canaqqalaseramik.az:5500/projects/delete/${image}/${id}`);
                const newProjectsList = projectsCollection.filter(i => i._id !== id)
                setProjectsCollection(newProjectsList)
            }catch(err){
                console.log(err)
            }
        }
        async function addProjectsItem(e){
            e.preventDefault();
            try{

                const formData = new FormData()
                formData.append('title', projectTitle)
                formData.append('city', projectCity)

                const fileInput = document.querySelector('input[type="file"]');
                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('image', fileInput.files[i]);
                  }
                const res = await axios.post('https://canaqqalaseramik.az:5500/projects/add_project', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                setProjectsCollection(prev => [...prev, res.data])
                setProjectCity('')
                setProjectTitle('')
            }catch(err){
                console.log(err)
            }
        }

        const updateItem = async (e) => {
            e.preventDefault()
            try{
                const res = await axios.put(`https://canaqqalaseramik.az:5500/gallery/${isUpdating}`, {title: updateItemText})
                const updatedItemIndex = projectsCollection.findIndex(i => i._id === isUpdating)
                projectsCollection[updatedItemIndex].title = updateItemText
                setUpdateItemText('')
                setIsUpdating('')
            }catch(err){
                console.log(err)
            }
        }

        const renderUpdateForm = () => {
            return(
                <form className={styles.update_gallery_form} onSubmit={e => {updateItem(e)}}>
                    <input placeholder="Proyektin adini deyish" type="text" onChange={e => {setUpdateItemText(e.target.value)}} value={updateItemText}/>
                    <button type="submit" className={styles.update}>Tezelemek</button>
                </form>
            )
        }
        useEffect(() => {
            async function getCollection (){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/projects/all_items`)
                setProjectsCollection(res.data)
            }catch(err){
                console.log(err)
            }
            }
            getCollection()
        }, [])
    return(
        <div className={styles.selected_option}>
            <h1>Projects list</h1>
            <div className={styles.add_form}>
                    <form className={styles.form} onSubmit={e => addProjectsItem(e)} encType="multipart/form-data">
                        <h4>Teze melumat elave etmek</h4>
                        <div className={styles.add_bloc}>
                            <input type="text" placeholder="Proyektin adı" onChange={(e) => {setProjectTitle(e.target.value)}} required value={projectTitle}/>
                            <input type="text" placeholder="Ölkə, şəhər" onChange={(e) => {setProjectCity(e.target.value)}} required value={projectCity}/>
                        </div>
                        <div className={styles.add_side}>
                            <div></div>
                            <div className={styles.add_btns}>
                                <div className={styles.load_file}>
                                    <label htmlFor="image2">Şəkil seç</label>
                                    <input type="file" name="image" id="image image2" style={{visibility: "hidden"}} multiple required/>
                                </div>
                                <input type="submit" value="Elave et" className={styles.add}/>
                            </div>
                        </div>
                    </form>
            </div>
            {
                projectsCollection.map((e, i) => {
                    // Нам нужно для картинок в gallery создать отдельные папки для каждой группы картин  
                    return <div key={i} className={styles.items}>
                        {
                            isUpdating === e._id 
                            ? renderUpdateForm() : 
                            <div className={styles.item}>
                                <div>
                                    <h3>{e.title}</h3>
                                    <p>{e.city}</p>
                                </div>
                                <div className={styles.item_buttons}>
                                    <button onClick={() => {setIsUpdating(e._id)}} className={styles.update}>Tezelemek</button>
                                    <button onClick={() => {deleteProjectsItem(e.image, e._id)}} className={styles.delete}>Silmek</button>
                                </div>
                            </div>
                        }
                    </div>
                })
            }
        </div>
    )
}

export default _Projects;