
export default function lazyLoadingReducer(state=[], action){
    switch(action.type){
        case 'GET_LAZYLOADING':
            return [...action.payload]
        case 'LAZY_LOAD':
            let i = action.index
            const sliced = action.main_arr.slice(action.payload.length, i * 9)
            return [...state, ...sliced]
        default:
            return [...state]
    }
}
