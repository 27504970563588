import React, { useEffect, useRef, useState } from "react";

import NavBar from "../nav/nav-bar";
import SideBar from "../nav/mobile";
import Collection from "./collections";
import FirstSlider from "./sliders/FirstSlider";
import Catalogue from "./catalogue";
import About from "./about";

import styles from "./home.module.css";

import to_top from "../assets/icons/to-top.svg";
import SecondSlider from "./sliders/SecondSlider";
import Loading from "../loading/loading";

const Home = ({to_top_btn, footer}) => {
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <div className={styles.home} style={!loaded ? {overflow: "hidden", height: "100vh"} : {overflow: "inherit"}}>
            {
                !loaded ? <div><Loading/></div> : '' 
            }
            <FirstSlider/>
            <Collection load = {setLoaded}/>
            <div id="social-float" className={styles.container}>
                <Catalogue/>
                <About/>
            </div>
        </div>
    )
}

export default Home;