import React, { useEffect } from "react";

import styles from "./showroom.module.css"
import { useDispatch } from "react-redux";

import sekil1 from "../assets/contacts/ck-showroom/Sekil 1.jpg"
import sekil3 from "../assets/contacts/ck-showroom/Sekil 3.jpg"
import sekil5 from "../assets/contacts/ck-showroom/Sekil 4.jpg"
import sekil6 from "../assets/contacts/ck-showroom/Sekil 5.jpg"
import sekil7 from "../assets/contacts/ck-showroom/Sekil 6.jpg"
import sekil8 from "../assets/contacts/ck-showroom/Sekil 7.jpg"
import sekil9 from "../assets/contacts/ck-showroom/Sekil 8.jpg"
import sekil10 from "../assets/contacts/ck-showroom/Sekil 9.jpg"
import sekil11 from "../assets/contacts/ck-showroom/Sekil 10.jpg"
import sekil12 from "../assets/contacts/ck-showroom/Sekil 11.jpg"

import k_photo1 from "../assets/contacts/k-showroom/20230711_043846410_iOS.jpg"
import k_photo2 from "../assets/contacts/k-showroom/20230711_043947679_iOS.jpg"
import k_photo3 from "../assets/contacts/k-showroom/20230711_044011295_iOS.jpg"
import k_photo4 from "../assets/contacts/k-showroom/20230711_044025222_iOS.jpg"
import k_photo5 from "../assets/contacts/k-showroom/20230711_044057728_iOS.jpg"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const Showrooms = ({text, f_name, loc, contact, w_time, h_img, gps}) => {

    const {room} = useParams()
    const dispatch = useDispatch()
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch({type: "STOP_INTERVAL"})
    }, [])
    return(
        <div className={`${styles.showroom}`}>
            <h1 className={styles.showroom_title} style={{fontWeight: '600', fontSize: '2.3em'}}>{f_name}</h1>
            <div className={styles.showroom_header}>
                <div className={styles.showroom_header_img} style={{backgroundImage: `url(${h_img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: "no-repeat"}}></div>
                <div className={styles.showroom_info}>
                    <p>{text}</p>
                    <ul className={styles.showroom_info_list}>
                        <li>
                            <ul>
                                <li style={{fontWeight: 600}}>BAKI</li>
                                <li><a href={gps} target="_blank">{loc}</a></li>
                                <li><a href={`tel:${contact.replace(" ", "")}`}>{contact}</a></li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>İş saatı</li>
                                <li>{w_time}</li>
                                <li style={{fontWeight: 600}}>Bazar: qeyri iş günü</li>
                            </ul>
                        </li>
                        <li><a href={gps} target="_blank" style={{fontWeight: 600}}>Xəritədə göstər</a></li>
                    </ul>
                </div>
            </div>
            {
                room !== 'kale' ? 
                <div className={`${styles.showroom_gallery} container`}>
                    <div>
                        <img alt=" " src={sekil1}/>
                        <img alt=" " src={sekil3}/>
                        <img alt=" " src={sekil5}/>
                        <img alt=" " src={sekil6}/>
                        <img alt=" " src={sekil12}/>
                    </div>
                    <div>
                        <img alt=" " src={sekil7}/>
                        <img alt=" " src={sekil8}/>
                        <img alt=" " src={sekil9}/>
                        <img alt=" " src={sekil10}/>
                        <img alt=" " src={sekil11}/>
                    </div>
                </div> : 
                <div className={`${styles.showroom_gallery} container`}>
                    <div>
                        <img alt=" " src={k_photo1}/>
                        <img alt=" " src={k_photo3}/>
                    </div>
                    <div>
                        <img alt=" " src={k_photo2}/>
                        <img alt=" " src={k_photo4}/>
                        <img alt=" " src={k_photo5}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default Showrooms;