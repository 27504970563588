import React from "react";
import styles from './side-bar.module.css';

import close_ico from '../../assets/icons/close.svg';
import location from '../../assets/icons/location.svg';
import instagram from '../../assets/icons/instagram.svg';
import { Link } from "react-router-dom/cjs/react-router-dom";

const SideBar = ({setSideBar, sideBar}) => {
    return(
        <div className={sideBar ? styles.side_bar_open : styles.side_bar_hidden}>
            <header>
                <img src={close_ico} alt='close ico' onClick={() => {setSideBar(false)}}/>
                <h3>Menu</h3>
            </header>
            <ul className={styles.side_bar}>
                <li className={styles.item_title}>
                    <h6>Haqqimizda</h6>
                    <Link to="/tarix"><p>Tarixçə</p></Link>
                </li>
                    <Link to="/ck-showroom/ck"><li>Çanakkale Seramik</li></Link>
                    <Link to="/k-showroom/kale"><li>Kale Seramik</li></Link>
                <li className={styles.item_title}>
                    <h6>Məhsullar və Xidmətlər</h6>
                    <p><Link to="/katalog/KAFEL">Kafel</Link></p>
                </li>
                <a href="/kataloq/METLAX"><li>Metlax</li></a>
                <a href="/kataloq/QRANİT"><li>Qranit</li></a>
                <a href="/kataloq/FLEX"><li>Flex</li></a>
                <a href="/kataloq/DEKOR"><li>Dekor</li></a>
                                
                                
                                
                                
                                
                <li className={styles.item_title}>
                    <h6>Portfolio</h6>
                    <Link to="/portfolio#brend"><p>Brendlər</p></Link>
                </li>
                <Link to="/portfolio#partner"><li>Partnyorlar</li></Link>
                <Link to="/portfolio#proyektler"><li>Proyektlər</li></Link>
            </ul>
            <footer className={styles.footer}>
                <p><a href="tel:+994552555719">(+994 55) 255 57 19</a></p>
                <p><a href="https://goo.gl/maps/syaXhjDnsooqKciw8"><img alt="location ico" src={location}/>Xətai pr. 43 Bakı Azərbaycan, AZ1008</a></p>
                <p><a href="https://goo.gl/maps/e7KqAfd18Kk1DegE9"><img alt="location ico" src={location}/>Xətai pr. 158 Bakı Azərbaycan, AZ1008</a></p>
                <p><a href="https://instagram.com/canaqqalaseramik?igshid=MzRlODBiNWFlZA=="><img alt="instagram ico" src={instagram}/>chanaqqalaseramik</a></p>
            </footer>
        </div>
    )
};

export default SideBar;