import React, { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from "./catalog.module.css"
import { useParams } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import Loading from "../loading/loading";
import { Image } from 'antd';


const CollectionProduct = () => {
    const {id} = useParams(),
    [product, setProduct] = useState({}),
    [loading, setLoading] = useState(true),
    [images, setImages] = useState([]),
    [similarProducts, setSimilarProducts] = useState([])

    useEffect(() => {
        async function getProduct(){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/slider/slider/${id}`)
                const similar = await axios.get('https://canaqqalaseramik.az:5500/slider/all_items')
                let images = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${res.data.Nomenklatura}`)
                setImages(images.data)
                const filtered = similar.data.filter((item) => item.Tekstura == res.data.Tekstura)
                setSimilarProducts(filtered.slice(5, 9))
                setProduct(res.data)
                setTimeout(() => {setLoading(false)}, 3000)
            }catch(err){
                console.log(err)
            }
        }
        getProduct()
    }, [])
    if(loading){
        return <div><Loading/></div>
    }
    return(
        <div className={styles.product}>
            <div className={styles.product_adv}>
                <div className={styles.product_props}>
                        <div>
                            <h4>Üzləmə</h4>
                            <div className={styles.inner_setting}>{product.Üzləmə}</div>
                        </div>
                        <div>
                            <h4>Çeşid</h4>
                            <div className={styles.inner_setting}>{product.Çeşid}</div>
                        </div>
                        <div>
                            <h4>Ölkə</h4>
                            <div className={styles.inner_setting}>{product.Ölkə}</div>
                        </div>
                        <div>
                            <h4>Tekstura</h4>
                            <div className={styles.inner_setting}>{product.Tekstura}</div>
                        </div>
                        <div>
                            <h4>Ölçü</h4>
                            <div className={styles.inner_setting}>{product.Ölçü}</div>
                        </div>
                </div>
            </div>
            <div className={styles.product_img} style={{
                                    backgroundImage: `url("https://canaqqalaseramik.az:5500/slider/image/${product.Nomenklatura}/${images[0]}")`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundColor: 'inherit'
                                }}>
                <h1>{product.Fancy}</h1>
            </div>
            <div className={styles.product_info}>
                <h1>{product.Nomenklatura}</h1>
                <div>
                    <TileImage product={product}/>
                </div>
            </div>
        </div>
    )
}

export default CollectionProduct;

const SimilarProduct = ({product}) => {
    const [imgLoading, setImgLoading] = useState(true),
    [imgLink, setImageLink] = useState('')
    useEffect(() => {
        async function getImages (){
            try{
                let images = await axios.get(`https://canaqqalaseramik.az:5500/product/image/${product.Ölkə}/${product.Çeşid}/${product.Ölçü}/${product.Nomenklatura}`)
                setImageLink(images.data[0])
            }catch(e){
                console.log(e)
            }finally{
                setImgLoading(false)
            }
        }
        getImages()
    }, [])
    if(imgLoading) return(
        <div className={styles.similar_product} style={{
            backgroundColor: "white",
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative'
        }}></div>
    )
    return(
        <div className={styles.similar_product}  style={{
                    backgroundImage: `url("https://canaqqalaseramik.az:5500/product/image/${product.Ölkə}/${product.Çeşid}/${product.Ölçü}/${product.Nomenklatura}/${imgLink}")`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative'
                }}>
            {
                <div style={{color: "black", backgroundColor: "#ffffff60", backdropFilter: "blur(10px)", width: "100%", position: 'absolute', left: '0', bottom: '0', padding: '20px 5px'}}>
                    <h3>{product.Nomenklatura}</h3>
                    <p>{product.Tekstura}</p>
                </div>
            }
        </div>
    )
} 

const TileImage = (props) => {
    const [images, setImages] = useState([]),
    [imgLoading, setImgLoading] = useState(true)
    useEffect(() => {
        async function getImages(){ 
            try{
                let images = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${props.product.Nomenklatura}`)
                setImages(images.data)
            }catch(err){
                console.log(err)
            }finally{
                setImgLoading(false)
            }
        }

        getImages()
    }, [])

    return(
        <div className={styles.images_gallery}>
            <Image.PreviewGroup
                preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
            {
                images.map((image, index) => {
                    return(
                        <div key={index} className={styles.image_tile} style={{
                            margin: "10px",
                            display: "inline-block"
                        }}>
                            <Image
                                preview={{
                                 mask: 'Baxmaq'   
                                }}
                                height={'300px'}
                                src={`https://canaqqalaseramik.az:5500/slider/image/${props.product.Nomenklatura}/${image}`}
                            />
                        </div>
                    )
                })
            }
            </Image.PreviewGroup>
        </div>
        )
}