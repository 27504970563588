import React, { useEffect, useState } from "react";

import styles from "./home.module.css";
import SecondSlider from "./sliders/SecondSlider";
import axios from "axios";
import { getSliderImages } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../loading/loading";

const Collection = (props) => {
    
    let [sliderImg, setImgSlider] = useState([])
    let [loaded, setLoaded] = useState(false)
    let [sliderList, setSliderList] = useState([])
    let [collectionData, setCollectionData] = useState([])
    const sliderImages = useSelector((state) => state.sliderImages)
    let dispatch = useDispatch()
    useEffect(() => {
        async function getImages(){
            try{
                const response = await axios.get('https://canaqqalaseramik.az:5500/api/folder-data/Slider')
                const res = await axios.get('https://canaqqalaseramik.az:5500/slider/all_items')
                res.data.forEach(e => {
                    dispatch(getSliderImages(e))
                })
                setSliderList(res.data)
                setImgSlider(response.data)
                setTimeout(() => {
                    props.load(true)
                }, 500)
            }catch(err){
                console.log(err)
            }
        }

        getImages()
    }, [])

    useEffect(() => {
        if(sliderImages.length === sliderList.length){
            rewriteArr()
        }
    }, [sliderList, sliderImages])

    const rewriteArr = () => {
        let arr = sliderList.map((item, index) => {
            const main_photo = () => {
                if(sliderImages[index].some(item => item.includes("Design"))){
                    return sliderImages[index].filter(e => e.includes('Design'))[0]
                }else return sliderImages[index][0]
            }
            return{
                _id: item._id,
                name: item.Ölkə,
                collections: [
                    {
                        _id: item._id,
                        name: item.Nomenklatura,
                        fancy: item.Fancy,
                        country: item.Ölkə,
                        img: main_photo()
                    }
                ]
            }
        })
        setCollectionData(arr)
    }

    let desktop_slider = []
    for(let i = 0; i < sliderImg.length; i++) {
        let arr = [];
        arr.push(sliderImg[i], sliderImg[i+1])
        i += 1
        desktop_slider.push(arr);
    }
    // const collectionData = [
    //     {
    //         name: 'Primavera',
    //         design: 'design classic',
    //         collections: [
    //             {
    //                 name: 'Como',
    //                 img: 'Sekil1.jpg'
    //             },
    //             {
    //                 name: 'cemento',
    //                 img: 'Sekil10.jpg'
    //             },
    //             {
    //                 name: 'wood',
    //                 img: 'Sekil11.jpg'
    //             },
    //             {
    //                 name: 'clam wood',
    //                 img: 'Sekil12.jpg'
    //             }
    //         ]
    //     },
    //     {
    //         name: 'golden tile',
    //         design: 'modern ceramics',
    //         collections: [
    //             {
    //                 name: 'Moco',
    //                 img: 'Sekil13.jpg'
    //             },
    //             {
    //                 name: 'Cota loco',
    //                 img: 'Sekil14.jpg'
    //             },
    //             {
    //                 name: 'wood',
    //                 img: 'Sekil15.jpg'
    //             },
    //             {
    //                 name: 'moca wood',
    //                 img: 'Sekil16.jpg'
    //             }
    //         ]
    //     },
    //     {
    //         name: 'brick style',
    //         design: 'indoor&outdoor',
    //         collections: [
    //             {
    //                 name: 'chomoloco',
    //                 img: 'Sekil17.jpg'
    //             },
    //             {
    //                 name: 'fabiraco',
    //                 img: 'Sekil18.jpg'
    //             },
    //             {
    //                 name: 'subezo',
    //                 img: 'Sekil2.jpg'
    //             },
    //             {
    //                 name: 'clam wood',
    //                 img: 'Sekil3.jpg'
    //             }
    //         ]
    //     },
    //     {
    //         name: 'Terragres',
    //         design: 'big sizes',
    //         collections: [
    //             {
    //                 name: 'Como',
    //                 img: 'Sekil4.jpg'
    //             },
    //             {
    //                 name: 'cemento',
    //                 img: 'Sekil5.jpg'
    //             },
    //             {
    //                 name: 'wood',
    //                 img: 'Sekil6.jpg'
    //             },
    //             {
    //                 name: 'clam wood',
    //                 img: 'Sekil7.jpg'
    //             }
    //         ]
    //     }
    // ]
    return(
        <div>
            <SecondSlider collectionData={collectionData}/>
            {
                collectionData.map((element, id) => {
                    return(
                        <section key={id} className={styles.collections_section}>
                            <h3>{element.name}</h3>
                            <ul className={styles.images}>
                                {element.collections.map((element, id) => {
                                    return(
                                        <li key={id} style={{backgroundColor: element.img}}>
                                            <h2>{element.name}</h2>
                                        </li>
                                    )
                                })}
                            </ul>
                        </section>
                    )
                })
            }
        </div>
    )
}

export default Collection;