import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../admin.module.css"

const _Gallery = () => {
    let [galleryCollection, setGalleryCollection] = useState([])
    let [galleryTitle, setGalleryTitle] = useState('')
    let [isUpdating, setIsUpdating] = useState('')
    let [updateItemText, setUpdateItemText] = useState('')
    
        async function deleteGalleryItem(image, id){
            try{
                const response = await axios.delete(`https://canaqqalaseramik.az:5500/gallery/delete/${image}/${id}`);
                const newGalleryList = galleryCollection.filter(i => i._id !== id)
                setGalleryCollection(newGalleryList)
            }catch(err){
                console.log(err)
            }
        }
        async function addGalleryItem(e){
            e.preventDefault();
            try{

                var currentDate = new Date();
                var randomNum = currentDate.getTime() % 100000;
                var randomNumString = randomNum.toString().padStart(5, '0');
                const formData = new FormData()
                formData.append('title', randomNumString + galleryTitle)

                const fileInput = document.querySelector('input[type="file"]');
                for (let i = 0; i < fileInput.files.length; i++) {
                    formData.append('image', fileInput.files[i]);
                  }
                const res = await axios.post('https://canaqqalaseramik.az:5500/gallery/add_gallery', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                setGalleryCollection(prev => [...prev, res.data])
            }catch(err){
                console.log(err)
            }
            setGalleryTitle('')
        }

        const updateItem = async (e) => {
            console.log(isUpdating)
            e.preventDefault()
            try{
                const updatedItemIndex = galleryCollection.findIndex(i => i._id === isUpdating)
                const res = await axios.put(`https://canaqqalaseramik.az:5500/gallery/${isUpdating}`, {title: 'updtd'+updateItemText})
                galleryCollection[updatedItemIndex].title = updateItemText
                setUpdateItemText('')
                setIsUpdating('')
            }catch(err){
                console.log(err)
            }
        }

        const renderUpdateForm = () => {
            return(
                <form className={styles.update_gallery_form} onSubmit={e => {updateItem(e)}}>
                    <input type="text" onChange={e => {setUpdateItemText(e.target.value)}} value={updateItemText} placeholder="Təzə ad yazın" required/>
                    <button type="submit" className={styles.update}>Tezelemek</button>
                </form>
            )
        }
        useEffect(() => {
            async function getCollection (){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/gallery/all_items`)
                setGalleryCollection(res.data)
            }catch(err){
                console.log(err)
            }
            }
            getCollection()
        }, [])
    return(
        <div className={styles.selected_option}>
            <h1>Gallery list</h1>
            <div className={styles.add_form}>
                    <form className={styles.form} onSubmit={e => addGalleryItem(e)} encType="multipart/form-data">
                        <h4>Teze melumat elave etmek</h4>
                        <div className={styles.add_bloc}>
                            <div>
                                <input type="text" placeholder="Gallery ucun title" value={galleryTitle} onChange={(e) => {setGalleryTitle(e.target.value)}} required/>
                            </div>
                            <div className={styles.add_side}>
                                <div></div>
                                <div className={styles.add_btns}>
                                    <div className={styles.load_file}>
                                        <label htmlFor="image5">Şəkil seç</label>
                                        <input type="file" name="image" id="image5" style={{visibility: "hidden"}} multiple required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="submit" value="Elave et" style={{
                            zIndex: 2
                        }} className={styles.add}/>
                    </form>
            </div>
            {
                galleryCollection.map((e, i) => {
                    // Нам нужно для картинок в gallery создать отдельные папки для каждой группы картин  
                    return <div key={i} className={styles.items}>
                        {
                            isUpdating === e._id 
                            ? renderUpdateForm() : 
                            <div className={styles.item}>
                                <div>
                                    <h3>
                                        {i + 1 + ' '} 
                                        {e.title}
                                    </h3>
                                </div>
                                <div className={styles.item_buttons}>
                                    <button onClick={() => {setIsUpdating(e._id)}} className={styles.update}>Tezelemek</button>
                                    <button onClick={() => {deleteGalleryItem(e.images, e._id)}} className={styles.delete}>Silmek</button>
                                </div>
                            </div>
                        }
                    </div>
                })
            }
        </div>
    )
}

export default _Gallery;