import React, { useState, useRef, useEffect } from "react"
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from "./home";
import Footer from "./footer";
import NavBar from "./nav/nav-bar";
import SideBar from "./nav/mobile";
import Catalog from "./catalog";

import to_top from "./assets/icons/to-top.svg";
import Portfolio from "./portfolio";
import Showrooms from "./showrooms";
import History from "./history";
import Product from "./catalog/product";

import contact_ck_h_img from "./assets/contacts/ck_h.png"
import contact_k_h_img from "./assets/contacts/k_h.png"
import Admin from "./admin/admin";
import Projects from "./portfolio/projects";
import Project from "./portfolio/project";
import Faq from "./faq/faq";

import main_bg from "./assets/seamless-pattern-abstract-background-with-futuristic-style-use-business-cover-banner_7505-1820.webp"
import CollectionProduct from "./catalog/collection";
import WpContact from "./wpcontact";



function App() {
  const [sideBar, setSideBar] = useState(false);
  const footer = useRef();
  const to_top_btn = useRef();
  function checkOffset() {
    if(!to_top_btn.current && !footer.current) return
    if(window.scrollY > (window.innerHeight)){
        to_top_btn.current.style.opacity = '1'
    }else to_top_btn.current.style.opacity = '.0'
    if((window.scrollY + window.innerHeight) >= (document.body.offsetHeight - footer.current.offsetHeight + 120)){
        to_top_btn.current.style.position = 'absolute';
        to_top_btn.current.style.bottom = footer.current.offsetHeight - 120 + 'px';
    }else {
        to_top_btn.current.style.position = 'fixed';
        to_top_btn.current.style.bottom = '20px';
    }
}
window.addEventListener("scroll", function(){
    checkOffset()
})
let [activateSearch, setActivateSearch] = useState(false)
function handleClick(event) {
  const clickedElement = event.target;
  if(clickedElement.id == ''){
    setActivateSearch(false)
  }
}
React.useEffect(() => {
  document.addEventListener('click', handleClick);

  return () => {
    document.removeEventListener('click', handleClick);
  };
}, []);
const ck = "Azərbaycanın ilk və ən böyük seramik satış mərkəzi olan “Çanakkale Seramik” 1994-cü ildən müştərilərin xidmətinə açılmışdır. Yarandığı ilk gündən bol çeşid və keyfiyyətli məhsullar təqdim edən şirkətimiz illərlə bu sahədə mümkün olan bütün yenilikləri istehlak bazarına təqdim etməklə daim müştərilərin rəğbətini və etibarını qazanmışdır. Avropa və Asiyanın ən etibarlı istehsalçılar ilə mütəmadi əməkdaşlığımız şirkətimizin nəinki ölkəmizdə, hətta Avropa və MDB hüdudlarında böyük etibar və rəğbət qazanmasına böyük zəmin yaratmışdır. Şirkətimiz  30 illik təcrübəsində daim prioritet olaraq müştəri məmnuniyyətini əsas alaraq zövqlə seçilmiş məhsullar, bol çeşid imkanları və ən uyğun qiymətləri təqdim etməyin qürunu yaşıyırıq.",
      kale = "“Çanakkale Seramik” şirkəti 2014-cü ildə fəaliyyətinin 20 illik yubileyini qeyd etdiyi bir tarixdə böyük qürur hissi ilə daha bir sərgi salonunun təqdimatını etdi. Beləliklə 2014-cü ildən “Kale Seramik” Sərgi-Satış salonumuz müştərilərin xidmətinə verildi. Bu mağazamızda sərgilərdən özəlliklə seçilmiş və məhdud sayda olan yüksək zövqlü məhsullar, müştərilərimizə təqdim edirik. Ən böyük öazəlliyimiz yeni yaranan bütün kolleksiyaları dərhal ölkəmizdə təqdim etmək imkanıdır. Beləliklə şirkətimiz yenə də ən son yenilikləri və trendləri müştərilərimizə ən uyğun qiymətlərlə təqdim etmək prinsipi ilə fəaliyyətinə qürürla tam sürət dəvam edir",
      k_loc = "https://goo.gl/maps/gdYDXbPCX5BDYJwb6",
      ck_loc = "https://goo.gl/maps/z3kfZSp6frac2s6z6"

  return (
    <Router>
    <div className={`app_screen`} id="to_top" style={{
      backgroundImage: `url('${main_bg}')`,
      backgroundSize: 'contain',
      backgroundSize: '500px'
    }}>
      <WpContact/>
      <a ref={to_top_btn}  href="#to_top" className='to_top'>
        <img alt="" src={to_top}/>
      </a>
      <div className={sideBar ? 'dark_shade' : 'light_shade'}></div>
      <SideBar setSideBar={setSideBar} sideBar={sideBar}/> 
      <NavBar setActivateSearch={setActivateSearch} activateSearch={activateSearch} sideBar={sideBar} setSideBar={setSideBar}/>
      <Switch>
        <Route path="/" exact><Home footer={footer} to_top_btn={to_top_btn} sideBar={sideBar} setSideBar={setSideBar}/></Route>
        <Route path="/kataloq/:style_param"><Catalog/></Route>
        <Route path="/kataloq"><Catalog/></Route>
        <Route path="/portfolio"><Portfolio/></Route>
        <Route path="/tarix"><History/></Route>
        <Route path="/admin__ck_k"><Admin/></Route>
        <Route path="/k-showroom/:room"><Showrooms h_img={contact_k_h_img} text={kale} gps={k_loc} f_name = 'Kale Seramik' loc='Xətai pr. 158, AZ1008' contact='+994 55 808 03 93' w_time='Bazar ertəsi-Şənbə: 09:00 – 19:00'/></Route>
        <Route path="/ck-showroom/:room"><Showrooms h_img={contact_ck_h_img} text={ck} gps={ck_loc} f_name = 'Çanakkale Seramik' loc='Xətai pr. 43, AZ1008' contact='+994 55 808 03 93' w_time='Bazar ertəsi-Şənbə: 09:00 – 19:00'/></Route>
        <Route path="/projects/:id"><Project/></Route>
        <Route path="/product/:id"><Product/></Route>
        <Route path="/product/popular/:id"><Product/></Route>
        <Route path="/faq"><Faq/></Route>
        <Route path="/collection/:id"><CollectionProduct/></Route>
      </Switch>
      <Footer footer={footer}/>
    </div> 
    </Router>
  );
}

export default App;
