import React, {useEffect, useState} from "react";
import axios from "axios";
import styles from "../admin.module.css"
import { Button, Image } from 'antd'

const _Slider = () => {
    let [sliderCollection, setSliderCollection] = useState([])
    let [sliderTitle, setSliderTitle] = useState('')
    let [sliderType, setSliderType] = useState('')
    let [sliderSize, setSliderSize] = useState('')
    let [sliderCountry, setSliderCountry] = useState('')
    let [sliderTexture, setSliderTexture] = useState('')
    let [sliderCover, setSliderCover] = useState('')
    let [sliderBrand, setSliderBrand] = useState('')
    let [isUpdating, setIsUpdating] = useState('')
    let [updateItemText, setUpdateItemText] = useState('')
    let [fancy, setFancy] = useState('')
    let [selectedImages, setSelectedImages] = useState(0)
    let [selectedUpdateImages, setSelectedUpdateImages] = useState(0)
    let [selectedUpdateAddImages, setSelectedUpdateAddImages] = useState(0)
    let [addImageLoading, setAddImageLoading] = useState(false)
    let [mainImageLoading, setMainImageLoading] = useState(false)
    let [updateLoading, setUdpateLoading] = useState(false)
    let [addLoading, setAddLoading] = useState(false)
    let [forceUpdate, setForceUpdate] = useState(1)

    async function deleteSliderItem(image, id){
        try{
            const response = await axios.delete(`https://canaqqalaseramik.az:5500/slider/delete/${image}/${id}`);
            const newSliderList = sliderCollection.filter(i => i._id !== id)
            setSliderCollection(newSliderList)
        }catch(err){
            console.log(err)
        }
    }
    async function addSliderItem(e){
        setAddLoading(true)
        e.preventDefault();
        try{

            const formData = new FormData()
            formData.append('title', sliderTitle)
            formData.append('type', sliderType)
            formData.append('size', sliderSize)
            formData.append('country', sliderCountry)
            formData.append('texture', sliderTexture)
            formData.append('cover', sliderCover)
            formData.append('brand', sliderBrand)
            formData.append('fancy', fancy)

            const fileInput = document.querySelector('input[type="file"]');
            for (let i = 0; i < fileInput.files.length; i++) {
                formData.append('image', fileInput.files[i]);
              }
            const res = await axios.post('https://canaqqalaseramik.az:5500/slider/add_slider', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setSliderCollection(prev => [...prev, res.data])
            setSliderTitle('')
            setSliderType('')
            setSliderSize('')
            setSliderCountry('')
            setSliderTexture('')
            setSliderCover('')
            setSliderBrand('')
            setFancy('')
            setAddLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    const updateImage = async (e, obj) => {
        setMainImageLoading(true)
        e.preventDefault()
        try{
            const formData = new FormData()
            const fileInput = document.querySelector('input[name="update_image"]');
            formData.append('main', obj.Nomenklatura)
            for (let i = 0; i < fileInput.files.length; i++) {
                formData.append('update_image', fileInput.files[i]);
            }
            const res = await axios.post('https://canaqqalaseramik.az:5500/slider/update_img', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setMainImageLoading(false)
        }catch(err){
            console.log(err)
        }
    }
    const addSliderImage = async (e, obj) => {
        setAddImageLoading(true)
        e.preventDefault()
        try{
            const formData = new FormData()
            const fileInput = document.querySelector('input[name="add_image"]');
            formData.append('add_image', obj.Nomenklatura)
            for (let i = 0; i < fileInput.files.length; i++) {
                formData.append('add_image', fileInput.files[i]);
            }
            const res = await axios.post('https://canaqqalaseramik.az:5500/slider/add_image_slider', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            setAddImageLoading(false)
            setForceUpdate(forceUpdate + 1)
        }catch(err){
            console.log(err)
        }
    }

    const updateItem = async (e) => {
        setUdpateLoading(true)
        e.preventDefault()
        try{
            const res = await axios.put(`https://canaqqalaseramik.az:5500/slider/update/${isUpdating}`, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                Çeşid: sliderType.length ? sliderType : '',
                Ölçü: sliderSize.length ? sliderSize : '',
                Ölkə: sliderCountry.length ? sliderCountry : '',
                Tekstura: sliderTexture.length ? sliderTexture : '',
                Üzləmə: sliderCover.length ? sliderCover : '',
                Fancy: fancy,
            })
            const updatedItemIndex = sliderCollection.findIndex(i => i._id === isUpdating)
            sliderCollection[updatedItemIndex].title = updateItemText
            // setUpdateItemText('')
            // setSliderType('')
            // setSliderSize('')
            // setSliderCountry('')
            // setSliderTexture('')
            // setSliderCover('')
            // setIsUpdating('')
            setFancy('')
            setUdpateLoading(false)
            alert('Təzələndi')
        }catch(err){
            console.log(err)
        }
    }

    function handleImageChange() {
        const imageInput = document.getElementById('image3');
        const selectedImages = imageInput.files;
        
        if (selectedImages) {
            setSelectedImages(selectedImages.length)
        } else {
            setSelectedImages([].length)
        }
    }
    function handleUpdateImageChange() {
        const imageInput = document.getElementById('image4');
        const selectedUpdateImages = imageInput.files;
        
        if (selectedUpdateImages) {
            setSelectedUpdateImages(selectedUpdateImages.length)
        } else {
            setSelectedUpdateImages([].length)
        }
    }

    const renderUpdateForm = (obj) => {
        setTimeout(() => {    
            // setFancy(obj.Fancy)
            // setSliderType(obj.Çeşid)
            // setSliderSize(obj.Ölçü)
            // setSliderCountry(obj.Ölkə)
            // setSliderTexture(obj.Tekstura)
            // setSliderCover(obj.Üzləmə)
        }, 300)
        return(
            <div className={styles.update_form}>
                <form onSubmit={e => {updateItem(e)}}>
                    <h4>Tezelemek</h4>
                    <div className={styles.update_inputs}>
                        <label for="title">Fancy ad: </label>
                        <input required id="title" type="text" defaultValue={obj.Fancy} onChange={(e) => {setFancy(e.target.value); console.log(fancy);}}/>
                    </div>
                    <div className={styles.update_selects}>
                        <label for="çeşid">Çeşid </label>
                        <select required id="çeşid" onChange={(e) => {setSliderType(e.target.value)}}>
                            <option value={obj.Çeşid}>{obj.Çeşid}</option>
                            <option value="QRANİT">QRANİT</option>
                            <option value="DEKOR">DEKOR</option>
                            <option value="KAFEL">KAFEL</option>
                            <option value="METLAX">METLAX</option>
                            <option value="FLEX">FLEX</option>
                        </select>
                        <label for="ölçü">Ölçü </label>
                        <select required id="ölçü" onChange={(e) => {setSliderSize(e.target.value)}}>
                            <option value={obj.Ölçü}>{obj.Ölçü}</option>
                            <option value="7.5X30">7.5x30</option>
                            <option value="10X20">10x20</option>
                            <option value="10X30">10x30</option>
                            <option value="15X60">15x60</option>
                            <option value="15X90">15x90</option>
                            <option value="19.7x19.7">19.7x19.7</option>
                            <option value="20X20">20x20</option>
                            <option value="20X50">20x50</option>
                            <option value="20X120">20x120</option>
                            <option value="25X40">25x40</option>
                            <option value="25X50">25x50</option>
                            <option value="25X60">25x60</option>
                            <option value="25X75">25x75</option>
                            <option value="30X30">30x30</option>
                            <option value="30X40">30x40</option>
                            <option value="30X50">30x50</option>
                            <option value="30X60">30x60</option>
                            <option value="30X90">30x90</option>
                            <option value="40X40">40x40</option>
                            <option value="45X45">45x45</option>
                            <option value="60X60">60x60</option>
                            <option value="60X120">60x120</option>
                            <option value="100X300">100x300</option>
                            <option value="120X180">120x180</option>
                        </select>
                        <label for="ölke">Ölke </label>
                        <select required id="ölke" onChange={(e) => {setSliderCountry(e.target.value)}}>
                            <option value={obj.Ölkə}>{obj.Ölkə}</option>
                            <option value="TÜRKİYƏ">TÜRKİYƏ</option>
                            <option value="ÇİN">ÇİN</option>
                            <option value="ÖZBƏKİSTAN">ÖZBƏKİSTAN</option>
                            <option value="RUSİYA">RUSİYA</option>
                            <option value="İRAN">İRAN</option>
                        </select>
                        <label for="tekstura" >Tekstura </label>
                        <select required id="tekstura" onChange={(e) => {setSliderTexture(e.target.value)}}>
                            <option value={obj.Tekstura}>{obj.Tekstura}</option>
                            <option value="Mərmər">Mərmər</option>
                            <option value="Dekorativ">Dekorativ</option>
                            <option value="Kərpic">Kərpic</option>
                            <option value="Monocolor">Monocolor</option>
                            <option value="Beton">Beton</option>
                            <option value="Taxta">Taxta</option>
                            <option value="Terrazzo">Terrazzo</option>
                            <option value="Daş">Daş</option>
                        </select>
                        <label for="üzleme">Üzleme </label>
                        <select required id="üzleme" onChange={(e) => {setSliderCover(e.target.value)}}>
                            <option value={obj.Üzləmə}>{obj.Üzləmə}</option>
                            <option value="Parlaq">Parlaq</option>
                            <option value="Mat">Mat</option>
                            <option value="Sugar Effect">Sugar Effect</option>
                        </select>
                    </div>
                        {/* <input type="submit" className={styles.update} value="Tezelemek" style={{
                            padding:"7px 15px",
                            margin: "5px"
                        }}/> */}
                        {updateLoading ? 
                        <Button className={styles.update} type="primary" loading style={{padding: 'inherit'}}>
                            Qözləyin
                        </Button> :
                        <Button className={styles.update} type="default" htmlType="submit"  style={{padding: 'inherit'}}>
                            Tezelemek
                        </Button>
                        }
                </form>
                <div>
                    <h3>Produkt şəkilləri</h3>
                    <TileImage product = {obj} update={forceUpdate}/>
                </div>
                <form onSubmit={(e) => {addSliderImage(e, obj)}} className={styles.addImage}>
                    <h3>Şəkil əlave et</h3>
                    <input type="file" name="add_image" multiple/>
                    
                    {addImageLoading ? 
                        <Button className={styles.update} type="primary" loading>
                            Qözləyin
                        </Button> :
                        <Button className={styles.update} type="default" htmlType="submit">
                            Yüklə
                        </Button>
                        }
                </form>
                <form onSubmit={(e) => {updateImage(e, obj)}}>
                    <div className={styles.add_btns}>
                        <h3>Əsas şəkili seç</h3>
                        <p>{obj.Nomenklatura}</p>
                        <div className={styles.load_file}>
                            <label htmlFor="image4">Şəkil seç</label> <span>Seçilən şəkil sayı: {selectedUpdateImages}</span>
                            <input type="file" name="update_image" id="image4" style={{visibility: "hidden"}} required onChange={() => {handleUpdateImageChange()}}/>
                        </div>
                    </div>
                    {/* <input type="submit" value='Əsas şəkili təzələ' className={styles.update} style={{
                            padding:"7px 15px",
                            margin: "5px"
                        }}/> */}
                        {mainImageLoading ? 
                        <Button className={styles.update} type="primary" loading style={{padding: 'inherit'}}>
                            Qözləyin
                        </Button> :
                        <Button className={styles.update} type="default" htmlType="submit"  style={{padding: 'inherit'}}>
                            Əsas şəkili təzələ
                        </Button>
                        }
                </form>
                <button onClick={() => {setIsUpdating('')}}>Close</button>
            </div>
        )
    }

    useEffect(() => {
        async function getSlider (){
            try{
                const res = await axios.get(`https://canaqqalaseramik.az:5500/slider/all_items`)
                setSliderCollection(res.data)
            }catch(err){
                console.log(err)
            }
        }
        getSlider()
    }, [])
    
    return(
        <div className={styles.selected_option}>
            <h1>Slider list</h1>
            <div className={styles.add_form}>
                <form className={styles.form} onSubmit={e => addSliderItem(e)} encType="multipart/form-data">
                <h4>Teze melumat elave etmek</h4>
                    <div>
                        <input type="text" placeholder="Nomenklatura adı" onChange={(e) => {setSliderTitle(e.target.value)}} required value={sliderTitle}/>
                        <input type="text" placeholder="Fancy ad" onChange={(e) => {setFancy(e.target.value)}} required value={fancy}/>
                        <label htmlFor="add_type">Çeşid</label>
                        <select id="add_type" onChange={(e) => setSliderType(e.target.value)} required>
                            <option value="">{sliderType}</option>
                            <option value="QRANİT">QRANİT</option>
                            <option value="DEKOR">DEKOR</option>
                            <option value="KAFEL">KAFEL</option>
                            <option value="METLAX">METLAX</option>
                            <option value="FLEX">FLEX</option>
                        </select>
                        <label htmlFor="add_size">Ölçü</label>
                        <select id="add_size" onChange={(e) => {setSliderSize(e.target.value)}}>
                            <option value="">{sliderSize}</option>
                            <option value="7.5X30">7.5x30</option>
                            <option value="10X20">10x20</option>
                            <option value="10X30">10x30</option>
                            <option value="15X60">15x60</option>
                            <option value="15X90">15x90</option>
                            <option value="19.7x19.7">19.7x19.7</option>
                            <option value="20X20">20x20</option>
                            <option value="20X50">20x50</option>
                            <option value="20X120">20x120</option>
                            <option value="25X40">25x40</option>
                            <option value="25X50">25x50</option>
                            <option value="25X60">25x60</option>
                            <option value="25X75">25x75</option>
                            <option value="30X30">30x30</option>
                            <option value="30X40">30x40</option>
                            <option value="30X50">30x50</option>
                            <option value="30X60">30x60</option>
                            <option value="30X90">30x90</option>
                            <option value="40X40">40x40</option>
                            <option value="45X45">45x45</option>
                            <option value="60X60">60x60</option>
                            <option value="60X120">60x120</option>
                            <option value="100X300">100x300</option>
                            <option value="120X180">120x180</option>
                        </select>
                        <label htmlFor="add_country">Ölkə</label>
                        <select id="add_country" onChange={(e) => {setSliderCountry(e.target.value)}}>
                            <option value="">{sliderCountry}</option>
                            <option value="TÜRKİYƏ">TÜRKİYƏ</option>
                            <option value="ÇİN">ÇİN</option>
                            <option value="ÖZBƏKİSTAN">ÖZBƏKİSTAN</option>
                            <option value="RUSİYA">RUSİYA</option>
                            <option value="İRAN">İRAN</option>
                        </select>
                        <label htmlFor="add_texture">Tekstura</label>
                        <select id="add_texture" onChange={(e) => {setSliderTexture(e.target.value)}}>
                            <option value="">{sliderTexture}</option>
                            <option value="Mərmər">Mərmər</option>
                            <option value="Dekorativ">Dekorativ</option>
                            <option value="Kərpic">Kərpic</option>
                            <option value="Monocolor">Monocolor</option>
                            <option value="Beton">Beton</option>
                            <option value="Taxta">Taxta</option>
                            <option value="Terrazzo">Terrazzo</option>
                            <option value="Daş">Daş</option>
                        </select>
                        <label htmlFor="add_cover">Üzləmə</label>
                        <select id="add_cover" onChange={(e) => {setSliderCover(e.target.value)}}>
                            <option value="">{sliderCover}</option>
                            <option value="Parlaq">Parlaq</option>
                            <option value="Mat">Mat</option>
                            <option value="Sugar Effect">Sugar Effect</option>
                        </select>
                    </div>
                    <div className={styles.add_side}>
                        <div></div>
                        <div className={styles.add_btns}>
                            <div className={styles.load_file}>
                                <label htmlFor="image3">Şəkil seç</label> <span>Seçilən şəkil sayı: {selectedImages}</span>
                                <input type="file" name="image" id="image3" style={{visibility: "hidden"}} multiple required onChange={(e) => {handleImageChange()}}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.addBtns}>
                        {addLoading ? 
                            <Button className={styles.add} type="primary" loading style={{padding: 'inherit', backgroundColor: '#4b2b2b'}}>
                                Qözləyin
                            </Button> :
                            <Button className={styles.add} type="default" htmlType="submit"  style={{padding: 'inherit', backgroundColor: 'inherit'}}>
                                Elave et
                            </Button>
                            }
                    </div>
                </form>
            </div>
            {
                sliderCollection.map((e, i) => {
                    // Нам нужно для картинок в gallery создать отдельные папки для каждой группы картин  
                    return <div key={i} className={styles.items}>
                        {
                            <div className={styles.item}>
                                <div>
                                    <h3>{e.Nomenklatura}</h3>
                                    <p>{e.Fancy}</p>
                                </div>
                                <div className={styles.item_buttons}>
                                    <button onClick={() => {deleteSliderItem(e.Nomenklatura, e._id)}} className={styles.delete}>Silmek</button>
                                    <button onClick={() => {
                                        setFancy(e.Fancy)
                                        setSliderType(e.Çeşid)
                                        setSliderSize(e.Ölçü)
                                        setSliderCountry(e.Ölkə)
                                        setSliderTexture(e.Tekstura)
                                        setSliderCover(e.Üzləmə)
                                        setIsUpdating(e._id)
                                    }} className={styles.update}>Tezelemek</button>
                                </div>
                                {
                                    isUpdating === e._id ? renderUpdateForm(e) : <></>
                                }
                            </div>
                        }
                    </div>
                })
            }
        </div>
    )
}


const TileImage = (props) => {
    const [images, setImages] = useState([]),
    [imgLoading, setImgLoading] = useState(true)

    const handleDeleteImage = async (product, imageName) => {
        try {
          const response = await axios.delete(`https://canaqqalaseramik.az:5500/slider/image/deleteImage/${product}/${imageName}`);
          console.log(response.data.message);
          let images = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${props.product.Nomenklatura}`)
          setImages(images.data)
          // Здесь вы можете обновить список изображений после удаления
          // Например, вызвать функцию для загрузки нового списка
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(() => {
        async function getImages(){ 
            try{
                let images = await axios.get(`https://canaqqalaseramik.az:5500/slider/folder/${props.product.Nomenklatura}`)
                setImages(images.data)
            }catch(err){
                console.log(err)
            }finally{
                setImgLoading(false)
            }
        }

        getImages()
    }, [props.update])

    return(
        <div className={styles.images_gallery}>
            <Image.PreviewGroup
                preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
            >
            {
                images.map((image, index) => {
                    return(
                        <div key={index} className={styles.image_tile} style={{
                            maxWidth: '100px',
                            maxHeight: '100px',
                            margin: "10px",
                            display: "inline-block"
                        }}>
                            <Image
                                preview={{
                                 mask: 'Baxmaq'   
                                }}
                                height={'100px'}
                                src={`https://canaqqalaseramik.az:5500/slider/image/${props.product.Nomenklatura}/${image}`}
                            />
                            <button style={{marginTop: '10px', cursor: 'pointer'}} onClick={() => {handleDeleteImage(props.product.Nomenklatura, image)}}>Şəkili sil</button>
                        </div>
                    )
                })
            }
            </Image.PreviewGroup>
        </div>
        )
}

export default _Slider;

